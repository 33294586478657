import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import OrderStatus from "../Dashboard-components/order-status";
import { useTranslation } from "react-i18next";

export default function OrderTable({ orders, handleToggleCallDuty }) {
  const { t } = useTranslation();

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };
  

  return (
    <div className="overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            <th className="xs:px-4 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-[14px] font-semibold text-gray-600 dark:text-white text-left truncate">
              {t("dashboard.table.name")}
            </th>
            <th className=" xs:px-2 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-[14px] font-semibold text-gray-600 dark:text-white text-left truncate">
              {t("dashboard.table.phoneNumber")}
            </th>
            <th className="xs:px-2 xs:py-1 xs:text-[10px] lg:py-2 lg:px-16 lg:text-[14px] font-semibold text-gray-600 dark:text-white text-left truncate">
              {t("dashboard.table.status")}
            </th>
            <th className="xs:px-2 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-[14px] font-semibold text-gray-600 dark:text-white text-left truncate">
            {t("dashboard.table.whenToCall")}
            </th>
            <th className="px-4 py-2 xs:py-1"></th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order._id} className="border-t">
              <td className="xs:px-4 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-base  text-gray-600 dark:text-white text-left truncate">
                {order.name}
              </td>
              <td className="xs:px-2 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-base  text-gray-600 dark:text-white text-left truncate">
                {order.phoneNumber}
              </td>
              <td className="xs:px-0 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-base  text-gray-600 dark:text-white text-left truncate">
                <OrderStatus status={order.status} />
              </td>
              <td className="xs:px-2 xs:py-1 xs:text-[10px] lg:py-2 lg:px-4 lg:text-base text-gray-600 dark:text-white text-left truncate">
                {order.whenToCall
                  ? formatDateTime(order.whenToCall)
                  : t("table.noScheduleSet")}
              </td>
              <td className="px-4 py-2 xs:px-0 xs:py-1 xs:text-xs lg:text-base">
                <button
                  onClick={() => handleToggleCallDuty(order._id)}
                  className="text-green-600 hover:text-green-500"
                >
                  <CheckIcon className="xs:w-4 xs:h-4 lg:w-5 lg:h-5 " />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
