import React, { useState, useRef, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function FilterDropdown({ handleFilterByStatus }) {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  const orderStatusStyles = {
    contracted:
      "text-green-600 w-full text-left px-4 py-2 text-sm hover:bg-green-100 dark:hover:bg-green-800",
    interested:
      "text-red-600 w-full text-left px-4 py-2 text-sm hover:bg-red-100 dark:hover:bg-red-800",
    potential:
      "text-orange-600 w-full text-left px-4 py-2 text-sm hover:bg-orange-100 dark:hover:bg-orange-800",
    purchased:
      "text-blue-700 w-full text-left px-4 py-2 text-sm hover:bg-blue-100 dark:hover:bg-blue-800",
    offered:
      "text-purple-600  w-full text-left px-4 py-2 text-sm hover:bg-purple-100 dark:hover:bg-purple-800",
  };

  const toggleFilter = () => {
    setSelectedFilter((prev) => (prev === "Status" ? null : "Status"));
  };

  const handleClick = (status) => {
    console.log("Selected Status:", status);
    handleFilterByStatus(status);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className="flex items-center mb-2 md:mb-0 px-3 py-2 border rounded-md text-black dark:text-white focus:outline-none"
        onClick={toggleFilter}
      >
        <span className="xs:text-[14px] lg:text-sm">
          {t("filter.filterTitle")}
        </span>
        <ChevronDownIcon
          className={`w-5 h-5 transition-transform ${
            selectedFilter ? "rotate-180" : ""
          }`}
        />
      </button>

      {selectedFilter === "Status" && (
        <div className="absolute -right-[50%] md:right-0 mt-2 w-48 bg-white dark:bg-slate-700 border rounded-md shadow-lg z-10">
          <button
            onClick={() => handleClick("all")}
            className="text-sm font-medium block w-full text-left px-4 py-2 text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600"
          >
            {t("filter.filterName")}
          </button>
          {Object.entries(orderStatusStyles).map(([status, style]) => (
            <button
              key={status}
              onClick={() => handleClick(status)}
              className={style}
            >
              {t(`filter.status.${status}`)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
