import { useEffect, useState, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import { BsDot } from "react-icons/bs";
import PurchaseDeleteModal from "./purchase-delete-modal";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { toast } from "react-toastify";

const PurchaseModal = ({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedPurchase,
  productNames,
  fetchPurchases,
}) => {
  const [purchase, setPurchase] = useState({
    supplierInfo: "",
    productName: "",
    quantity: 0,
    quantityForFree: 0,
    price: { usd: "", uzs: "" },
    costs: {
      shippingCosts: { usd: "", uzs: "" },
      customDuties: { usd: "", uzs: "" },
      vat: { usd: "", uzs: "" },
      paymentProcessingFees: { usd: "", uzs: "" },
      currencyConversionFees: { usd: "", uzs: "" },
      storageCosts: { usd: "", uzs: "" },
      certificationFees: { usd: "", uzs: "" },
      localDeliveryCosts: { usd: "", uzs: "" },
      insurance: { usd: "", uzs: "" },
      brokerageFees: { usd: "", uzs: "" },
      repackagingFees: { usd: "", uzs: "" },
      environmentalTaxes: { usd: "", uzs: "" },
      others: { usd: "", uzs: "" },
    },
    date: "",
    comments: [],
  });

  const [newComment, setNewComment] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const commentsEndRef = useRef(null);
  const userName = localStorage.getItem("userName");
  const { t } = useTranslation();
  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shippingCosts, setShippingCosts] = useState(false);
  const [customDuties, setCustomDuties] = useState(false);
  const [vat, setVat] = useState(false);
  const [paymentProcessingFees, setPaymentProcessingFees] = useState(false);
  const [currencyConversionFees, setCurrencyConversionFees] = useState(false);
  const [storageCosts, setStorageCosts] = useState(false);
  const [certificationFees, setCertificationFees] = useState(false);
  const [localDeliveryCosts, setLocalDeliveryCosts] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [brokerageFees, setBrokerageFees] = useState(false);
  const [repackagingFees, setRepackagingFees] = useState(false);
  const [environmentalTaxes, setEnvironmentalTaxes] = useState(false);
  const [others, setOthers] = useState(false);
  const [currency, setCurrency] = useState("usd");
  const [count, setCount] = useState(0);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const currencyOptions = [
    { value: "usd", label: "usd" },
    { value: "uzs", label: "uzs" },
  ];

  useEffect(() => {
    const storedCurrency = localStorage.getItem("currency");
    if (storedCurrency) {
      setCurrency(storedCurrency);
    }
  }, []);
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedCurrency = localStorage.getItem("currency");
      if (updatedCurrency) {
        setCurrency(updatedCurrency);
      }
    };
  
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  

  const handleCurrencyChange = (event) => {
    const selectedValue = event.target.value;
    setCurrency(event.target.value);

    const symbol = selectedValue === "usd" ? "$" : "uzs";
    setPurchase((prev) => ({ ...prev, priceSymbol: symbol }));
  };

  useEffect(() => {
    if (isEditMode && selectedPurchase) {
      setPurchase({
        supplierInfo: selectedPurchase.supplierInfo || "",
        productName: selectedPurchase.productName || "",
        quantity: selectedPurchase.quantity || 0,
        quantityForFree: selectedPurchase.quantityForFree || 0,
        price: selectedPurchase.price || { usd: "", uzs: "" },
        costs: {
          shippingCosts: selectedPurchase.costs?.shippingCosts || {
            usd: "",
            uzs: "",
          },
          customDuties: selectedPurchase.costs?.customDuties || {
            usd: "",
            uzs: "",
          },
          vat: selectedPurchase.costs?.vat || { usd: "", uzs: "" },
          paymentProcessingFees: selectedPurchase.costs
            ?.paymentProcessingFees || { usd: "", uzs: "" },
          currencyConversionFees: selectedPurchase.costs
            ?.currencyConversionFees || { usd: "", uzs: "" },
          storageCosts: selectedPurchase.costs?.storageCosts || {
            usd: "",
            uzs: "",
          },
          certificationFees: selectedPurchase.costs?.certificationFees || {
            usd: "",
            uzs: "",
          },
          localDeliveryCosts: selectedPurchase.costs?.localDeliveryCosts || {
            usd: "",
            uzs: "",
          },
          insurance: selectedPurchase.costs?.insurance || { usd: "", uzs: "" },
          brokerageFees: selectedPurchase.costs?.brokerageFees || {
            usd: "",
            uzs: "",
          },
          repackagingFees: selectedPurchase.costs?.repackagingFees || {
            usd: "",
            uzs: "",
          },
          environmentalTaxes: selectedPurchase.costs?.environmentalTaxes || {
            usd: "",
            uzs: "",
          },
          others: selectedPurchase.costs?.others || { usd: "", uzs: "" },
        },
        date: selectedPurchase.date || "",
        comments: selectedPurchase.comments || [],
      });
    } else {
      resetForm();
    }
  }, [isEditMode, selectedPurchase]);

  const resetForm = () => {
    setPurchase({
      supplierInfo: "",
      productName: "",
      quantity: 0,
      quantityForFree: 0,
      price: { usd: "", uzs: "" },
      costs: {
        shippingCosts: { usd: "", uzs: "" },
        customDuties: { usd: "", uzs: "" },
        vat: { usd: "", uzs: "" },
        paymentProcessingFees: { usd: "", uzs: "" },
        currencyConversionFees: { usd: "", uzs: "" },
        storageCosts: { usd: "", uzs: "" },
        certificationFees: { usd: "", uzs: "" },
        localDeliveryCosts: { usd: "", uzs: "" },
        insurance: { usd: "", uzs: "" },
        brokerageFees: { usd: "", uzs: "" },
        repackagingFees: { usd: "", uzs: "" },
        environmentalTaxes: { usd: "", uzs: "" },
        others: { usd: "", uzs: "" },
      },
      date: "",
      comments: [],
    });
  };

  const handleChange = (key, value) => {
    if (key in purchase.costs) {
      setPurchase((prev) => ({
        ...prev,
        costs: { ...prev.costs, [key]: value },
      }));
    } else {
      setPurchase((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleSave = async () => {
    if (!purchase.productName.trim()) {
      alert("Iltimos, barcha majburiy maydonlarni to‘ldiring.");
      return;
    }
  
    try {
      const selectedCurrency = localStorage.getItem("currency") || "usd";
  
      const commentsToSave = isEditMode
        ? purchase.comments
        : [...purchase.comments, newComment];
  
      const formattedCosts = Object.keys(purchase.costs).reduce((acc, key) => {
        const value = parseFloat(purchase.costs[key][selectedCurrency]);
        if (value) {
          acc[key] = { [selectedCurrency]: value };
        }
        return acc;
      }, {});
  
      const formattedPurchase = {
        supplierInfo: purchase.supplierInfo,
        productName: purchase.productName,
        quantity: parseInt(purchase.quantity),
        quantityForFree: parseInt(purchase.quantityForFree) || 0,
        price: { [selectedCurrency]: parseFloat(purchase.price[selectedCurrency]) || 0 },
        costs: formattedCosts,
        purchaseDate: purchase.date,
        comments: commentsToSave,
      };
  
      const url = isEditMode
        ? `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/purchases/${selectedPurchase._id}`
        : `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/purchases`;
      const method = isEditMode ? "PATCH" : "POST";
  
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify(formattedPurchase),
      });
  
      if (!response.ok) {
        throw new Error("Saqlashda xatolik yuz berdi.");
      }
  
      const savedPurchase = await response.json();
      onSave(savedPurchase);
      resetForm();
      onClose();
      setNewComment("");
    } catch (error) {
      console.error("Xatolik:", error.message);
      alert("Ma'lumotni saqlashda muammo yuz berdi.");
    }
  };
  

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  useEffect(() => {
    if (isOpen && purchase.comments.length > 0) {
      scrollToBottom();
    }
  }, [isOpen, purchase.comments]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    if (!selectedPurchase?._id) {
      alert(
        "Xatolik: Purchase ID aniqlanmadi. Iltimos, purchase ni saqlang yoki qayta urinib ko‘ring."
      );
      return;
    }

    const timestamp = new Date().toISOString();
    const newCommentObject = {
      text: newComment,
      timestamp,
    };

    try {
      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/purchases/${selectedPurchase._id}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "CSRF-Token": csrfToken,
          },
          body: JSON.stringify({
            comments: [...(selectedPurchase.comments || []), newCommentObject],
          }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        console.error("Error response:", responseData);
        throw new Error("Sharh qo‘shishda xatolik yuz berdi.");
      }

      setPurchase((prev) => ({
        ...prev,
        comments: [...(prev.comments || []), newCommentObject],
      }));
      setNewComment("");
    } catch (error) {
      console.error("Xato:", error.message);
      alert("Sharh qo‘shishda muammo yuz berdi.");
    }
  };

  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/purchases/${selectedPurchase._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "CSRF-Token": csrfToken,
          },
        }
      );

      fetchPurchases();

      if (!response.ok) {
        throw new Error("Failed to delete the purchase");
      }

      onClose();
    } catch (error) {
      console.error("Error deleting purchase:", error.message);
      alert("Failed to delete purchase. Please try again.");
    }
  };

  const confirmDelete = () => {
    setShowConfirmModal(true);
  };

  if (!isOpen) return null;

  const productOptions = productNames.map((name) => ({
    value: name,
    label: name,
  }));

  return (
    <div
      onClick={() => onClose()}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white overflow-auto max-h-[80%] dark:bg-gray-800 p-6 rounded-lg w-[90%] lg:w-full max-w-lg"
      >
        <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
          {isEditMode ? "Edit Purchase" : "Add Purchase"}
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalSupplier")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.supplierInfo || ""}
              onChange={(e) => handleChange("supplierInfo", e.target.value)}
            />
          </div>

          {/* name */}
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalName")}
            </label>
            <CreatableSelect
              isClearable
              options={productOptions}
              value={productOptions.find(
                (option) => option.value === purchase.productName
              )}
              onChange={(selectedOption) =>
                handleChange("productName", selectedOption?.value || "")
              }
              className="mt-1"
            />
          </div>

          {/* quantity */}
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalQuantity")}
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.quantity || ""}
              onChange={(e) => handleChange("quantity", e.target.value)}
            />
          </div>

          {/* quantity for free */}
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalQuantityForFree")}
            </label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.quantityForFree || ""}
              onChange={(e) => handleChange("quantityForFree", e.target.value)}
            />
          </div>

          {/* price */}
          <div>
            <label className="dark:text-white">
              {t("contracts.contractsModal.contractPrice")}
            </label>
            <div className="relative flex items-center">
              <div className="absolute left-2 text-gray-500">
                {currency === "usd" ? "$" : "uzs"}
              </div>
              <input
                type="number"
                value={purchase.price[currency] || ""}
                onChange={(e) =>
                  setPurchase((prev) => ({
                    ...prev,
                    price: {
                      ...prev.price,
                      [currency]: e.target.value,
                    },
                  }))
                }
                className="w-full pl-10 pr-3 py-2 border rounded-l-md"
              />
              <select
                value={currency}
                onChange={handleCurrencyChange}
                className="px-3 py-[10px] uppercase border-l-0 rounded-r-md bg-gray-50"
              >
                {currencyOptions.map((currencyOption) => (
                  <option
                    key={currencyOption.value}
                    value={currencyOption.value}
                  >
                    {currencyOption.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* date */}
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
              {t("purchases.purchasesModal.purchasesModalDate")}
            </label>
            <input
              type="date"
              className="w-full px-3 py-2 border rounded-md"
              value={purchase.date || ""}
              onChange={(e) => handleChange("date", e.target.value)}
            />
          </div>

          {/* additional costs */}

         {(shippingCosts || purchase?.costs?.shippingCosts?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Shipping costs
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase?.costs?.shippingCosts?.[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            shippingCosts: {
              ...prev.costs.shippingCosts,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
 {(customDuties || purchase?.costs?.customDuties?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Custom duties
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase?.costs?.customDuties?.[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            customDuties: {
              ...prev.costs.customDuties,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
 {(vat || purchase?.costs?.vat?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      VAT
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.vat[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            vat: {
              ...prev.costs.vat,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(paymentProcessingFees || purchase?.costs?.paymentProcessingFees?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Payment Processing Fees
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.paymentProcessingFees[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            paymentProcessingFees: {
              ...prev.costs.paymentProcessingFees,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(currencyConversionFees || purchase?.costs?.currencyConversionFees?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Currency Conversion Fees
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.currencyConversionFees[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            currencyConversionFees: {
              ...prev.costs.currencyConversionFees,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(storageCosts || purchase?.costs?.storageCosts?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Storage Costs
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.storageCosts[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            storageCosts: {
              ...prev.costs.storageCosts,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(certificationFees || purchase?.costs?.certificationFees?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Certification Fees
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.certificationFees[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            certificationFees: {
              ...prev.costs.certificationFees,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(localDeliveryCosts || purchase?.costs?.localDeliveryCosts?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Local Delivery Costs
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.localDeliveryCosts[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            localDeliveryCosts: {
              ...prev.costs.localDeliveryCosts,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(insurance || purchase?.costs?.insurance?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Insurance
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.insurance[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            insurance: {
              ...prev.costs.insurance,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(brokerageFees || purchase?.costs?.brokerageFees?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Brokerage Fees
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.brokerageFees[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            brokerageFees: {
              ...prev.costs.brokerageFees,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(repackagingFees || purchase?.costs?.repackagingFees?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Repackaging Fees
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.repackagingFees[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            repackagingFees: {
              ...prev.costs.repackagingFees,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
{(environmentalTaxes || purchase?.costs?.environmentalTaxes?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Environmental Taxes
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase.costs.environmentalTaxes[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            environmentalTaxes: {
              ...prev.costs.environmentalTaxes,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}

 {(others || purchase?.costs?.others?.usd > 0) && (
  <div>
    <label className="block text-gray-600 dark:text-gray-300 text-sm capitalize">
      Others
    </label>
    <input
      type="number"
      className="px-3 py-2 border rounded-md"
      value={purchase?.costs?.others?.[currency] || ""}
      onChange={(e) =>
        setPurchase((prev) => ({
          ...prev,
          costs: {
            ...prev.costs,
            others: {
              ...prev.costs.others,
              [currency]: e.target.value,
            },
          },
        }))
      }
    />
  </div>
)}
        </div>
        <div
          onMouseLeave={() => setDropdownOpen(false)}
          className="flex items-center relative justify-center"
        >
          <button
            onClick={() => setDropdownOpen((prev) => !prev)}
            className="py-2 px-4 flex items-center justify-center my-5 gap-5"
          >
            <ControlPointIcon />{" "}
            {t("purchases.purchasesModal.purchasesModalAdditionalCost")}
          </button>
          {dropdownOpen && (
            <div className="absolute top-14 w-64 max-h-52 overflow-auto bg-white border border-gray-300 rounded shadow-lg">
              <div className="flex flex-col">
                <div className="">
                  {shippingCosts ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setShippingCosts(true)}
                    >
                      {t("purchases.purchasesModal.purchasesShippingCosts")}
                    </p>
                  )}
                  {customDuties ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setCustomDuties(true)}
                    >
                      {t("purchases.purchasesModal.purchasesCustomDuties")}
                    </p>
                  )}
                  {vat ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setVat(true)}
                    >
                      {t("purchases.purchasesModal.purchasesVat")}
                    </p>
                  )}
                  {paymentProcessingFees ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setPaymentProcessingFees(true)}
                    >
                      {t(
                        "purchases.purchasesModal.purchasesPaymentProcessingFees"
                      )}
                    </p>
                  )}
                  {currencyConversionFees ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setCurrencyConversionFees(true)}
                    >
                      {t(
                        "purchases.purchasesModal.purchasesCurrencyConversionFees"
                      )}
                    </p>
                  )}
                  {storageCosts ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setStorageCosts(true)}
                    >
                      {t("purchases.purchasesModal.purchasesStorageCosts")}
                    </p>
                  )}
                  {certificationFees ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setCertificationFees(true)}
                    >
                      {t("purchases.purchasesModal.purchasesCertificationFees")}
                    </p>
                  )}
                  {localDeliveryCosts ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setLocalDeliveryCosts(true)}
                    >
                      {t(
                        "purchases.purchasesModal.purchasesLocalDeliveryCosts"
                      )}
                    </p>
                  )}
                  {insurance ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setInsurance(true)}
                    >
                      {t("purchases.purchasesModal.purchasesInsuranceCosts")}
                    </p>
                  )}
                  {brokerageFees ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setBrokerageFees(true)}
                    >
                      {t("purchases.purchasesModal.purchasesBrokerageFees")}
                    </p>
                  )}
                  {repackagingFees ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setRepackagingFees(true)}
                    >
                      {t("purchases.purchasesModal.purchasesRepackagingFees")}
                    </p>
                  )}
                  {environmentalTaxes ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setEnvironmentalTaxes(true)}
                    >
                      {t(
                        "purchases.purchasesModal.purchasesEnvironmentalTaxes"
                      )}
                    </p>
                  )}
                  {others ? (
                    ""
                  ) : (
                    <p
                      className="py-2 hover:bg-gray-100 cursor-pointer px-4"
                      onClick={() => setOthers(true)}
                    >
                      {t("purchases.purchasesModal.purchasesOthers")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 dark:text-white">
            {t("leads.leadmodal.commentTitle")}
          </h3>
          <div className="mb-4 max-h-24 overflow-y-auto custom-scrollbar">
            {purchase.comments.length > 0 ? (
              purchase.comments.map((comment, index) => (
                <div
                  key={index}
                  className="flex items-start gap-3 p-3 bg-white dark:bg-gray-800 rounded-lg"
                >
                  <div
                    className="bg-[#BF360C] text-white rounded-full w-[45px] h-10 flex items-center justify-center"
                    title={userName}
                  >
                    <p>{userName ? userName.charAt(0).toUpperCase() : "U"}</p>
                  </div>

                  <div className="flex flex-col w-full">
                    <div className="flex gap-1 items-center">
                      <p className="text-sm font-semibold text-gray-800 dark:text-white">
                        {userName}
                      </p>
                      <BsDot className="w-5 h-5 text-gray-400" />
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {isEditMode
                          ? new Date(
                              comment.timestamp || comment.date
                            ).toLocaleString()
                          : new Date(comment.date).toLocaleString()}
                      </p>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                      {comment.text}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                No comments
              </p>
            )}
            <div ref={commentsEndRef} />
          </div>

          <div className="flex items-center gap-3 my-4">
            <textarea
              rows={1}
              value={newComment}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white"
              placeholder="Add Comment"
            />
            {isEditMode && (
              <button
                onClick={handleAddComment}
                className="bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                {t("leads.leadmodal.commentSend")}
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-between">
          {isEditMode && (
            <button
              onClick={confirmDelete}
              className="px-4 py-2 bg-red-600 hover:bg-red-500 text-white rounded-md"
            >
              {t("purchases.purchasesModal.purchasesModalDeleteBTN")}
            </button>
          )}
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                handleClose();
                setCount(0);
              }}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-black rounded-md"
            >
              {t("purchases.purchasesModal.purchasesModalCloseBTN")}
            </button>
            {count == 0 ? (
              <button
                onClick={() => {
                  handleSave();
                  setCount(count + 1);
                }}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
              >
                {t("purchases.purchasesModal.purchasesModalSaveBTN")}
              </button>
            ) : (
              <button className="px-4 py-2 bg-blue-400 hover:bg-blue-500 text-white rounded-md">
                {t("purchases.purchasesModal.purchasesModalSaveBTN")}
              </button>
            )}
          </div>
        </div>
      </div>
      <PurchaseDeleteModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowConfirmModal(false);
          handleDelete();
        }}
      />
    </div>
  );
};

export default PurchaseModal;
