import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductsChart from "../components/Sales-components/products-chart";
import TotalSales from "../components/Sales-components/total-sales";
import TotalProfit from "../components/Sales-components/profit-sales";
import { AiFillProduct } from "react-icons/ai";
import { HiCurrencyDollar } from "react-icons/hi2";
import { TbSitemapFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import { CalendarIcon } from "@heroicons/react/24/solid";

const Sales = () => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api";
  const { t } = useTranslation();
  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");
  const currency = localStorage.getItem("currency") || "usd"; 

  const [filter, setFilter] = useState("week"); 
  const [productsMargin, setProductsMargin] = useState({});
  const [salesMargin, setSalesMargin] = useState({});
  const [profitMargin, setProfitMargin] = useState({});
  const [totatSold, setTotalSold] = useState(0);
  const [totalProfit, setTotalProfit] = useState({});
  const [totalSales, setTotalSales] = useState({});
  const [averageCosts, setAverageCosts] = useState({});

  useEffect(() => {
    if (role !== "director") {
      navigate("/");
    }
  }, [role, navigate]);

  const getMargins = async () => {
    try {
      const response = await fetch(`${base_URL}/analytics/margin/${filter}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
      });
      if (!response.ok) {
        throw new Error(`Error fetching margins: ${response.status}`);
      }
      const data = await response.json();
      setProductsMargin(data?.analytics?.productAnalytics || {});
      setSalesMargin(data?.analytics?.totalSales || {});
      setProfitMargin(data?.analytics?.profitShare || {});
      setTotalSold(data?.analytics?.totalQuantitySold || 0);
      setTotalProfit(data?.analytics?.totalProfit || {});
      setTotalSales(data?.analytics?.totalSales || {});
      setAverageCosts(data?.analytics?.averageCosts || {});
    } catch (error) {
      console.error("Error fetching margins:", error);
    }
  };

  useEffect(() => {
    getMargins(); 
  }, [filter]);

  const formatCurrency = (value) => {
    if (currency === "usd") {
      return `$ ${value?.usd ?? 0}`;
    } else if (currency === "uzs") {
      return `${value?.uzs ?? 0} UZS`;
    }
    return value ?? 0;
  };

  return (
    <div className="max-w-screen overflow-hidden xs:p-2 lg:p-8 xs:ml-0 lg:ml-14 bg-white dark:bg-gray-800">
      <h1 className="text-2xl mb-4 font-semibold text-gray-800 dark:text-gray-100">
        {t("sales.salesTitle")}
      </h1>
      <div className="flex items-center justify-evenly flex-wrap gap-5 my-10 lg:gap-10">
        <div className="py-5 px-5 lg:w-1/4 rounded-md bg-white shadow-md dark:bg-gray-800 border flex items-center gap-5">
          <AiFillProduct className="text-blue-500 dark:text-blue-400 w-7 h-auto" />
          <p className="font-medium text-gray-800 dark:text-gray-100">
            {t("sales.salesProducts")} <br />
            <CountUp start={0} end={totatSold} duration={2.5} separator="," />
          </p>
        </div>
        <div className="py-5 px-5 lg:w-1/4 rounded-md bg-white shadow-md dark:bg-gray-800 border flex items-center gap-5">
          <TbSitemapFilled className="text-blue-500 dark:text-blue-400 w-7 h-auto" />
          <p className="font-medium text-gray-800 dark:text-gray-100">
            {t("sales.salesContracts")} <br />
            <CountUp
              start={0}
              end={currency === "usd" ? totalSales?.usd : totalSales?.uzs}
              duration={2.5}
              separator=","
              prefix={currency === "usd" ? "$ " : ""}
              suffix={currency === "uzs" ? " UZS" : ""}
              decimals={currency === "usd" ? 2 : 0}
            />
          </p>
        </div>
        <div className="py-5 px-5 lg:w-1/4 rounded-md bg-white shadow-md dark:bg-gray-800 flex border items-center gap-5">
          <HiCurrencyDollar className="text-blue-500 dark:text-blue-400 w-7 h-auto" />
          <p className="font-medium text-gray-800 dark:text-gray-100">
            {t("sales.salesProfit")} <br />
            <CountUp
              start={0}
              end={currency === "usd" ? totalProfit?.usd : totalProfit?.uzs}
              duration={2.5}
              separator=","
              prefix={currency === "usd" ? "$ " : ""}
              suffix={currency === "uzs" ? " UZS" : ""}
              decimals={currency === "usd" ? 2 : 0}
            />
          </p>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 py-4 px-6 rounded-md">
        <div className="flex justify-end gap-4 items-center mb-6">
        <div className="relative">
  <CalendarIcon className="w-5 h-5 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400" />
  <select
    value={filter}
    onChange={(e) => setFilter(e.target.value)}
    className="pl-8 pr-4 py-2 rounded border-2 border-gray-300 outline-none dark:bg-gray-800 dark:text-white"
  >
    {Object.entries(t("analyticsSales.data", { returnObjects: true })).map(([key, label]) => (
      <option key={key} value={key}>
        {label}
      </option>
    ))}
  </select>
</div>

        </div>
        <div className="flex items-center justify-evenly flex-wrap gap-5 lg:gap-10">
          <ProductsChart
            productsMargin={productsMargin}
          />
          <TotalSales totalSales={totalSales} />
          <TotalProfit profitMargin={totalProfit} />
        </div>
      </div>
    </div>
  );
};

export default Sales;
