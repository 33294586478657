import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { FaUserAlt, FaUsers, FaFileContract } from "react-icons/fa";
import { CalendarIcon, UsersIcon } from "@heroicons/react/20/solid";
import { useSpring, animated } from "react-spring";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const formatNumber = (num) => {
  return new Intl.NumberFormat("en-US").format(num);
};

const AnimatedNumber = ({ value }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: value,
    config: { duration: 1000 },
  });

  return (
    <animated.span>
      {number.to((n) => formatNumber(Math.floor(n)))}
    </animated.span>
  );
};

const Dashboard = () => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("Weekly");
  const [segment, setSegment] = useState("Leads");
  const [selectedTimeRange, setSelectedTimeRange] = useState("AllTimes");
const [selectedSegment, setSelectedSegment] = useState(null);
const [currency, setCurrency] = useState(localStorage.getItem("currency") || "usd");

  const [summaryData, setSummaryData] = useState({
    leads: 0,
    customers: 0,
    totalAmount: 0,
    totalSpend: 0,
    totalSum: 0,
  });
  const { t } = useTranslation();
  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [leadsResponse, contractsResponse, customersResponse] = await Promise.all([
          fetch("https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/leads", {
            headers: {
              Authorization: `Bearer ${token}`,
              "CSRF-Token": csrfToken,
            },
          }),
          fetch("https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/contracts", {
            headers: {
              Authorization: `Bearer ${token}`,
              "CSRF-Token": csrfToken,
            },
          }),
          fetch("https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/customers", {
            headers: {
              Authorization: `Bearer ${token}`,
              "CSRF-Token": csrfToken,
            },
          }),
        ]);
  
        const leadsData = await leadsResponse.json();
        const contractsData = await contractsResponse.json();
        const customersData = await customersResponse.json();
  
        let filteredLeads = [];
        let filteredCustomers = [];
        let filteredContracts = [];
  
        if (filter === "AllTimes") {
          filteredLeads = mergeDuplicateDates(
            leadsData.data.map((item) => ({
              name: formatDate(new Date(item.createdAt)),
              value: item.value || 1,
            }))
          );
        
          filteredCustomers = mergeDuplicateDates(
            customersData.data.map((item) => ({
              name: formatDate(new Date(item.createdAt)),
              value: item.value || 1,
            }))
          );
        
          filteredContracts = mergeDuplicateDates(
            contractsData.data.map((item) => ({
              name: formatDate(new Date(item.contractDate)),
              value: item.totalAmount?.usd || 0,
            }))
          );
        }
         else {
          filteredLeads = filterDataByIntervalForLeadsCustomers(leadsData.data, filter, "createdAt");
          filteredCustomers = filterDataByIntervalForLeadsCustomers(customersData.data, filter, "createdAt");
           filteredContracts = filterDataByIntervalForContracts(
            contractsData.data,
            filter,
            "contractDate",
            currency === "uzs" ? "totalAmount.uzs" : "totalAmount.usd"
        );
        
        }
  
        const totalLeads = filteredLeads.reduce((acc, item) => acc + item.value, 0);
        const totalCustomers = filteredCustomers.reduce((acc, item) => acc + item.value, 0);
        const totalAmount = filteredContracts.reduce((acc, item) => acc + item.value, 0);
        const totalSum= contractsData.totalSum;
  
        setSummaryData({
          leads: totalLeads,
          customers: totalCustomers,
          totalAmount,
          totalSum,
        });
  
        const mergedData = mergeChartData(
          filteredLeads,
          filteredContracts,
          "name",
          "leads",
          "totalAmount",
          filteredCustomers,
          "customers"
        );
  
        if (segment === "AllSegments") {
          setChartData(mergedData);
        } else if (segment === "Leads") {
          setChartData(filteredLeads);
        } else if (segment === "Customers") {
          setChartData(filteredCustomers);
        } else if (segment === "TotalAmount") {
          setChartData(filteredContracts);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [filter, segment]);
  
  
  const filterDataByIntervalForLeadsCustomers = (data, interval, dateKey) => {
    const now = new Date().getTime();
    const intervalStartDate = getStartDateForInterval(interval).getTime();
    const groupedData = {};

    data.forEach((item) => {
      const itemDate = new Date(item[dateKey]).getTime();
      if (itemDate >= intervalStartDate && itemDate <= now) {
        const formattedDate = formatDate(itemDate);
        groupedData[formattedDate] = (groupedData[formattedDate] || 0) + (item.value || 1);
      }
    });

    return formatGroupedData(groupedData);
  };

  function mergeDuplicateDates(data) {
    const dateMap = new Map();
  
    data.forEach((item) => {
      const { name, value } = item;
      if (dateMap.has(name)) {
        dateMap.set(name, dateMap.get(name) + value); 
      } else {
        dateMap.set(name, value);
      }
    });
  
    return Array.from(dateMap, ([name, value]) => ({ name, value }));
  }
  

  const filterDataByIntervalForContracts = (data, interval, dateKey, valueKey) => {
    const now = new Date().getTime();
    const intervalStartDate = getStartDateForInterval(interval).getTime();
    const groupedData = {};

    data.forEach((item) => {
      const itemDate = new Date(item[dateKey]).getTime();
      if (itemDate >= intervalStartDate && itemDate <= now) {
        const formattedDate = formatDate(itemDate);
        const value = valueKey.split(".").reduce((acc, key) => acc?.[key], item) || 0;
        groupedData[formattedDate] = (groupedData[formattedDate] || 0) + value;
      }
    });

    return formatGroupedData(groupedData);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const formatGroupedData = (groupedData) => {
    return Object.entries(groupedData)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => {
        const [dayA, monthA, yearA] = a.name.split(".");
        const [dayB, monthB, yearB] = b.name.split(".");
        return new Date(`${yearA}-${monthA}-${dayA}`) - new Date(`${yearB}-${monthB}-${dayB}`);
      });
  };

  const getStartDateForInterval = (interval) => {
    const now = new Date();
  
    switch (interval) {
      case "Weekly":
        now.setDate(now.getDate() - 7);
        break;
      case "Monthly":
        now.setMonth(now.getMonth() - 1);
        break;
      case "ThreeMonths":
        now.setMonth(now.getMonth() - 3);
        break;
      case "SixMonths":
        now.setMonth(now.getMonth() - 6);
        break;
      case "Yearly":
        now.setFullYear(now.getFullYear() - 1);
        break;
      case "AllTimes":
        return null; 
      default:
        return null; 
    }
    return now; 
  };

  const mergeChartData = (leads, contracts, key, leadKey, contractKey, customers, customerKey) => {
    const merged = {}; 

    leads.forEach((item) => {
        merged[item[key]] = { 
            name: item[key], 
            leads: item.value || 0,  
            totalAmount: 0,  
            customers: 0 
        };
    });

    contracts.forEach((item) => {
        const contractValue = item.value || 0;
        if (merged[item[key]]) {
            merged[item[key]].totalAmount += contractValue;  
        } else {
            merged[item[key]] = { 
                name: item[key], 
                leads: 0,  
                totalAmount: contractValue, 
                customers: 0 
            };
        }
    });

    customers.forEach((item) => {
        if (merged[item[key]]) {
            merged[item[key]].customers = item.value || 0;
        } else {
            merged[item[key]] = { 
                name: item[key], 
                leads: 0,  
                totalAmount: 0, 
                customers: item.value || 0 
            };
        }
    });

    return Object.values(merged).map(item => ({
        ...item,
    }));
};


  const renderChart = () => {
    if (segment === "Leads") {
      return <Line type="monotone" dataKey="leads" stroke="#8884d8" />;
    } else if (segment === "Customers") {
      return <Line type="monotone" dataKey="customers" stroke="#82ca9d" />;
    } else if (segment === "TotalAmount") {
      return <Line type="monotone" dataKey="totalAmount" stroke="#ffc658" />;
    } else if (segment === "AllSegments") {
      return (
        <>
          <Line type="monotone" dataKey="leads" stroke="#ffc658" />
          <Line type="monotone" dataKey="customers" stroke="#82ca9d" />
        </>
      );
    }
  };
  console.log(`data: ${JSON.stringify(chartData)}`);

  const sortedChartData = chartData.sort((a, b) => {
    const dateA = new Date(a.name.split('.').reverse().join('-')); 
    const dateB = new Date(b.name.split('.').reverse().join('-'));
    return dateA - dateB; 
  });

  console.log(`Sorted: ${JSON.stringify(sortedChartData)}`);
  
  return (
    <div className="max-w-screen overflow-hidden xs:p-2 lg:p-8 xs:ml-0 lg:ml-14 dark:bg-gray-800">
      <h1 className="text-2xl mb-4 font-semibold dark:text-white">{t("dashboard.dashboardTitle")}</h1>
      <div className="flex gap-4 items-center my-4">
            </div>
            <div className="grid xs:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
  <div className="bg-white dark:bg-gray-800 dark:border dark:border-gray-50 p-4 rounded shadow flex items-center space-x-4">
    <FaUserAlt size={24} className="text-[#37B5FE]" />
    <div>
      <h2 className="text-sm font-semibold dark:text-white">{t("analytics.Leads")}</h2>
      <p className="text-xl font-bold dark:text-white">
        <AnimatedNumber value={summaryData.leads} />
      </p>
    </div>
  </div>

  <div className="bg-white dark:bg-gray-800 dark:border dark:border-gray-50 p-4 rounded shadow flex items-center space-x-4">
    <FaUsers size={24} className="text-[#37B5FE]" />
    <div>
      <h2 className="text-sm font-semibold dark:text-white">{t("analytics.Customers")}</h2>
      <p className="text-xl font-bold dark:text-white">
        <AnimatedNumber value={summaryData.customers} />
      </p>
    </div>
  </div>

  <div className="bg-white dark:bg-gray-800 dark:border dark:border-gray-50 p-4 rounded shadow flex items-center space-x-4">
  <FaFileContract size={24} className="text-[#37B5FE]" />
  <div>
    <h2 className="text-sm font-semibold dark:text-white">{t("analytics.ContractsTotalAmount")}</h2>
    <p className="text-xl font-bold dark:text-white">
      {currency === "uzs" ? (
        <>
          <AnimatedNumber
            value={filter === "AllTimes" ? summaryData.totalSum.uzs : summaryData.totalAmount}
          />{" "}
          so'm
        </>
      ) : (
        <>
          $
          <AnimatedNumber
            value={filter === "AllTimes" ? summaryData.totalSum.usd : summaryData.totalAmount}
          />
        </>
      )}
    </p>
  </div>
</div>

</div>


      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-auto bg-white rounded shadow w-full dark:bg-gray-800 scrollbar-hide">
          <div className="flex xs:flex-col lg:flex-row lg:justify-between mb-3">
            <h2 className="text-xl mb-4 dark:text-white">{t("analytics.performanceOverview")}</h2>

            <div className="flex xs:justify-start lg:justify-end gap-4 items-center">
              <div className="relative">
                <CalendarIcon className="w-5 h-5 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400" />
                <select
  value={filter}
  onChange={(e) => setFilter(e.target.value)}
  className="pl-8 pr-4 py-2 rounded border-2 border-gray-300 outline-none dark:bg-gray-800 dark:text-white"
>
  {Object.entries(t("analytics.data", { returnObjects: true })).map(([key, label]) => (
    <option key={key} value={key}>
      {label}
    </option>
  ))}
</select>
              </div>
              <div className="relative">
                <UsersIcon className="w-5 h-5 absolute top-1/2 left-2 transform -translate-y-1/2 text-gray-400" />
                <select
  value={segment}
  onChange={(e) => setSegment(e.target.value)}
  className="pl-8 pr-4 py-2 rounded border-2 border-gray-300 outline-none dark:bg-gray-800 dark:text-white"
>
  {Object.entries(t("analytics.segments", { returnObjects: true })).map(([key, label]) => (
    <option key={key} value={key}>
      {label}
    </option>
  ))}
</select>
              </div>
            </div>
          </div>

    <ResponsiveContainer width="100%" height={300}>
    <LineChart
      data={sortedChartData}
      margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
    >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis dataKey={segment === "AllSegments" ? "totalAmount" : "value"} />
    <Line
      type="monotone"
      dataKey={segment === "AllSegments" ? "totalAmount" : "value"}
      stroke="#8884d8"
    />
    <Tooltip />
    <Legend />
    {renderChart()}
  </LineChart>
</ResponsiveContainer>

        </div>
      )}
    </div>
  );
};

export default Dashboard;
