import React, { useEffect, useState, useRef } from "react";
import { MdOutlineDarkMode, MdOutlineLightMode, MdMenu } from "react-icons/md";
import { user, logo } from "../assets";
import { NotificationBell, Dropdown } from "../components";
import { Drawer } from "@mui/material";
import navigationLinks from "../constants/links";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const flags = {
  en: "/ukFlag.png",
  uz: "/uzFlag.png",
  ru: "/ruFlag.png",
};

const currencies = [
  { code: "USD", label: "USD", icon: "/united-states.png" },
  { code: "UZS", label: "UZS", icon: "/uzbekistan.png" },
];

export default function Navbar({ isDarkMode, toggleTheme, changeLang }) {
  const [selectedLang, setSelectedLang] = useState("en");
  const [selectedCurrency, setSelectedCurrency] = useState("usd");
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [currencyDropdownOpen, setCurrencyDropdownOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const userDropdownRef = useRef(null);
  const langDropdownRef = useRef(null);
  const currencyDropdownRef = useRef(null);
  const location = useLocation();
  const [userRole, setUserRole] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const storedUserRole = localStorage.getItem("role");
    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
  }, []);
  

  useEffect(() => {
    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      setSelectedLang(savedLang);
    }

    const storedUserName = localStorage.getItem("name");
    if (storedUserName) {
      setUserName(storedUserName);
    } else {
      setUserName("User");
    }

    const savedCurrency = localStorage.getItem("currency");
    if (savedCurrency) {
      setSelectedCurrency(savedCurrency);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target) &&
        langDropdownRef.current &&
        !langDropdownRef.current.contains(event.target) &&
        currencyDropdownRef.current &&
        !currencyDropdownRef.current.contains(event.target)
      ) {
        setUserDropdownOpen(false);
        setLangDropdownOpen(false);
        setCurrencyDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userDropdownOpen, langDropdownOpen, currencyDropdownOpen]);

  // Drawer should close automatically on large screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setDrawerOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleUserDropdown = () => {
    setUserDropdownOpen((prev) => !prev);
    setLangDropdownOpen(false);
    setCurrencyDropdownOpen(false);
  };

  const toggleLangDropdown = () => {
    setLangDropdownOpen((prev) => !prev);
    setUserDropdownOpen(false);
    setCurrencyDropdownOpen(false);
  };

  const toggleCurrencyDropdown = () => {
    setCurrencyDropdownOpen((prev) => !prev);
    setLangDropdownOpen(false);
    setUserDropdownOpen(false);
  };

  const changeLangHandler = (lang) => {
    setSelectedLang(lang);
    localStorage.setItem("language", lang);
    changeLang(lang);
    setLangDropdownOpen(false);
  };

  const changeCurrencyHandler = (currency) => {
    setSelectedCurrency(currency.toLowerCase());
    localStorage.setItem("currency", currency.toLowerCase());
    setCurrencyDropdownOpen(false);

    window.location.reload();
  };

  const logoutHandler = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("language");
    localStorage.removeItem("currency");
    window.location.href = "/login";
  };

  return (
    <nav className="bg-[#FAFAFB] w-full px-5 dark:bg-gray-800 text-gray-800 dark:text-white py-4 fixed lg:top-0 lg:left-80 lg:w-[calc(100%-20rem)] z-10">
      <div className="flex items-center w-full justify-between lg:justify-end lg:gap-7 gap-2">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={toggleUserDropdown}
        >
          <div
            className="bg-[#BF360C] text-white rounded-full w-10 h-10 flex items-center justify-center cursor-pointer"
            title={userName}
          >
            <p>{userName.charAt(0).toUpperCase()}</p>
          </div>
          <p className="text-black dark:text-white">{userName}</p>
        </div>
        <Dropdown
          avatar={user}
          username={userName}
          dropdownOpen={userDropdownOpen}
          setDropdownOpen={setUserDropdownOpen}
          logout={logoutHandler}
        />

  {/* Currency Selector */}
  <div className="relative">
    <button
      className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white p-2 rounded-md flex items-center space-x-3 hover:bg-gray-300 dark:hover:bg-gray-600 transition-all"
      onClick={toggleCurrencyDropdown}
      aria-label="Select Currency"
    >
      <img
        src={currencies.find((currency) => currency.code.toLowerCase() === selectedCurrency)?.icon}
        alt="Currency Flag"
        className="h-6 w-6"
      />
      <span className="font-semibold">{selectedCurrency.toUpperCase()}</span>
    </button>

    {currencyDropdownOpen && (
      <ul className="absolute bg-white dark:bg-gray-700 mt-2 p-2 px-4 rounded-md shadow-lg max-h-60 overflow-y-auto left-0 w-max">
        {currencies.map(({ code, label, icon }) => (
          <li
            key={code}
            className="group flex items-center space-x-3 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 rounded-md transition-all"
            onClick={() => changeCurrencyHandler(code)}
          >
            <img src={icon} alt={label} className="h-6 w-6" />
            <span className="font-medium">{label}</span>
          </li>
        ))}
      </ul>
    )}
  </div>


        <NotificationBell />

        <button onClick={toggleTheme} className="text-[#37B5FE]">
          {isDarkMode ? (
            <MdOutlineLightMode className="h-8 w-8" />
          ) : (
            <MdOutlineDarkMode className="h-8 w-8" />
          )}
        </button>
        <div className="relative xs:hidden lg:block">
  <button
    className="bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-white p-2 rounded-md flex items-center space-x-2 hover:bg-gray-300 dark:hover:bg-gray-600 transition-all"
    onClick={toggleLangDropdown}
    aria-label="Select Language"
  >
    <img
      src={flags[selectedLang]}
      alt="Language Flag"
      className="h-5 w-5"  // currency bayroqlaridan kichikroq qilish uchun h-5 va w-5 ishlatildi
    />
    <span className="font-semibold">{selectedLang.toUpperCase()}</span>
  </button>

  {langDropdownOpen && (
    <ul className="absolute bg-white dark:bg-gray-700 mt-2 p-2 px-2 rounded-md shadow-lg max-h-60 overflow-y-auto left-0 w-max">
      {Object.entries(flags).map(([key, src]) => (
        <li
          key={key}
          className="group flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 rounded-md transition-all"
          onClick={() => changeLangHandler(key)}
        >
          <img src={src} alt={key} className="h-5 w-5" />  
          <span className="font-medium">{key.toUpperCase()}</span>
        </li>
      ))}
    </ul>
  )}
</div>


        <button
          className="text-gray-800 dark:text-white lg:hidden"
          onClick={() => setDrawerOpen(true)}
        >
          <MdMenu className="h-8 w-8" />
        </button>
      </div>


      

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          document.body.style.overflow = "auto";
        }}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          className:
            "bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-300", // Ensure PaperProps use correct dark mode classes
          style: {
            position: "fixed",
            top: 0,
            right: 0,
            width: "320px",
            height: "100%",
            zIndex: 1300,
            overflowY: "auto",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: backdrop customization
          },
        }}
        onOpen={() => {
          document.body.style.overflow = "hidden"; // Disable background scrolling
        }}
      >
        <div className="w-full h-full">
          {/* Drawer Header with User Info */}
          <div className="flex flex-col items-center justify-center px-4 py-6 border-b border-gray-200 dark:border-gray-700">
            <div
              className="bg-[#BF360C] text-white rounded-full w-16 h-16 flex items-center justify-center cursor-pointer"
              title={userName}
            >
              <p className="text-2xl">{userName.charAt(0).toUpperCase()}</p>
            </div>
            <p className="mt-2 text-xl text-gray-800 dark:text-gray-300">{userName}</p>
          </div>

          {/* Language Flags and Currency Selector */}
          <div className="flex items-center justify-between px-6 mt-4">
            {/* Language Flags */}
            <div className="flex space-x-3">
              {Object.entries(flags).map(([key, src]) => (
                <img
                  key={key}
                  src={src}
                  alt={key}
                  className={`h-8 w-8 cursor-pointer rounded-full ${
                    selectedLang === key ? "ring-2 ring-blue-500" : ""
                  }`}
                  onClick={() => changeLangHandler(key)}
                />
              ))}
            </div>

            {/* Currency Selector */}
            <div className="relative">
              <select
                className="bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-white p-2 rounded-md"
                value={selectedCurrency}
                onChange={(e) => changeCurrencyHandler(e.target.value)}
              >
                {currencies.map(({ code, label }) => (
                  <option key={code} value={code.toLowerCase()}>
                    {label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="flex flex-col space-y-2 px-6 py-6">
  {navigationLinks
    .filter((link) => {
      if (userRole === "manager") {
        return link.role === "manager"; // Only show manager role links
      } else if (userRole === "director") {
        return true; // Show all links for director
      }
      return true; // Show all links for other roles
    })
    .map((link, index) => (
      <Link
        to={link.route}
        key={index}
        className={`flex items-center text-normal font-semibold transition ${
          location.pathname === link.route
            ? "bg-[#37B5FE] text-white"
            : "text-gray-600 dark:text-gray-300"
        } rounded-lg py-4 px-3`}
      >
        <i
          className={`${link.icon} text-2xl mr-4 ${
            location.pathname === link.route
              ? "text-white"
              : "text-[#37B5FE]"
          }`}
        ></i>
        {link.display_name}
      </Link>
    ))}
</div>

        </div>
      </Drawer>
    </nav>
  );
}
