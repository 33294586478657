import { useState, useEffect } from "react";

const ConversionModal = ({ isOpen, onClose, onSave, isEditMode, selectedConversion }) => {
  const [conversion, setConversion] = useState({
    name: "", 
    clicks: "", 
    conversions: "", 
  });

  useEffect(() => {
    if (isEditMode && selectedConversion) {
      setConversion(selectedConversion);
    } else {
      setConversion({
        name: "",
        clicks: "",
        conversions: "",
      });
    }
  }, [isEditMode, selectedConversion]);

  const handleChange = (key, value) => {
    setConversion((prev) => ({ ...prev, [key]: value }));
  };

  const handleSave = () => {
    if (
      conversion.name.trim() === "" ||
      conversion.clicks.trim() === "" ||
      conversion.conversions.trim() === ""
    ) {
      return;
    }

    onSave(conversion);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
          {isEditMode ? "Edit Conversion" : "Add Conversion"}
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">Campaign Name</label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={conversion.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">Clicks</label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={conversion.clicks}
              onChange={(e) => handleChange("clicks", e.target.value)}
            />
          </div>

          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">Conversions</label>
            <input
              type="number"
              className="w-full px-3 py-2 border rounded-md"
              value={conversion.conversions}
              onChange={(e) => handleChange("conversions", e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-black rounded-md"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversionModal;
