import { PencilSquareIcon } from "@heroicons/react/20/solid";
import OrderStatus from "../Dashboard-components/order-status";
import { useTranslation } from "react-i18next";

export default function LeadTable({ leads, onEditLead }) {
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const { t } = useTranslation();

  console.log(leads);

  return (
    <div className="overflow-auto max-h-[60vh] border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            <th
              scope="col"
              className="xs:hidden xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.id")}
            </th>
            <th
              scope="col"
              className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.name")}
            </th>
            <th
              scope="col"
              className="xs:px-2 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.phone")}
            </th>
            <th
              scope="col"
              className="xs:px-2 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.product")}
            </th>
            <th
              scope="col"
              className="xs:px-3 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.whenToCall")}
            </th>
            <th
              scope="col"
              className="xs:px-3 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.status")}
            </th>
            <th
              scope="col"
              className="xs:pl-2 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("leads.leadsTable.actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead, index) => (
            <tr key={lead.id} className="border-t">
              <td className="xs:hidden xs:pl-4 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {index + 1}
              </td>
              <td className="xs:pl-4 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {lead.name}
              </td>
              <td className="xs:pl-1 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {lead.phoneNumber || "Not provided"}
              </td>
              <td className="xs:pl-1 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {lead.productName || "No product"}
              </td>
              <td className="xs:pl-0 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {lead.whenToCall
                  ? formatDateTime(lead.whenToCall)
                  : "No schedule set"}
              </td>
              <td className="xs:pl-1 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base max-w-full overflow-hidden">
                <OrderStatus status={lead.status} />
              </td>

              <td className="xs:pl-3 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2  lg:text-base">
                <button
                  onClick={() => onEditLead(lead)}
                  className="text-green-600 hover:text-green-800"
                  title="Edit Lead"
                >
                  <PencilSquareIcon className="w-5 h-5 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
