import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerFilter from "./cusromerFilter";

const CustomerContractModal = ({ customer, onClose }) => {
  const userContracts = customer?.contracts || [];
  const [filteredContract, setFilteredContract] = useState([]);
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "usd");
  const { t } = useTranslation();

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const handleFilterByDate = (sortOrder) => {
    const sortedContracts = [...(userContracts || [])];
    sortedContracts.sort((a, b) => {
      const dateA = new Date(a.contractDate);
      const dateB = new Date(b.contractDate);
      return ["Ascending", "O'sish tartibi", "По возрастанию"].includes(sortOrder)
        ? dateA - dateB
        : dateB - dateA;
    });
    setFilteredContract(sortedContracts);
  };

  const handleFilterByProductName = (selectedProductName) => {
    const filteredContracts = userContracts.filter(
      (contract) => contract.productName === selectedProductName
    );
    setFilteredContract(filteredContracts);
  };

  return (
    <>
      <div
        onClick={() => onClose(false)}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 w-[1000px] h-[400px] overflow-hidden overflow-y-auto"
        >
          <div>
            <div className="flex items-center justify-between">
              <h2 className="font-normal text-xl sm:text-2xl mb-3 dark:text-white">
                {t("customers.customerContactModal.customerContractModal")}
                <span className="font-semibold dark:text-blue-400 inline text-xl sm:text-2xl mb-3 text-blue-700">
                  {customer?.name}
                </span>
              </h2>
              <div>
                <CustomerFilter
                  contracts={userContracts}
                  handleFilterByDate={handleFilterByDate}
                  handleFilterByProductName={handleFilterByProductName}
                />
              </div>
            </div>
            <div>
              {userContracts.length === 0 ? (
                <p className="text-center my-5 text-gray-600 dark:text-white">
                  No contracts available.
                </p>
              ) : (
                <div className="overflow-x-auto">
                  <table className="w-full table-auto border-collapse">
                    <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
                      <tr>
                        {[ 
                          "customers.customerContactModal.customerContractModalId",
                          "customers.customerContactModal.customerContractModalName",
                          "customers.customerContactModal.customerContractModalQuantity",
                          "customers.customerContactModal.customerContractModalDate",
                          "customers.customerContactModal.customerContractModalTotalAmount",
                          "customers.customerContactModal.customerContractModalManagerName",
                        ].map((headingKey) => (
                          <th
                            key={headingKey}
                            className="px-6 py-2 text-sm sm:text-base font-semibold text-gray-600 dark:text-white text-left"
                          >
                            {t(headingKey)}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(filteredContract.length > 0
                        ? filteredContract
                        : userContracts
                      ).map((contract, index) => (
                        <tr className="border-t" key={contract._id}>
                          <td className="px-6 py-2 text-gray-800 dark:text-white">
                            {index + 1}
                          </td>
                          <td className="px-6 py-2 text-gray-800 dark:text-white">
                            {contract.product.name}
                          </td>
                          <td className="px-6 py-2 text-gray-800 dark:text-white">
                            {contract.product.quantity?.toLocaleString()}
                          </td>
                          <td className="px-6 py-2 text-gray-800 dark:text-white">
                            {formatDateTime(contract.date)}
                          </td>
                          <td className="px-6 py-2 text-gray-800 dark:text-white">
                            {currency === "usd"
                              ? `$ ${contract?.product.totalAmount?.usd?.toLocaleString()}`
                              : `${contract?.product.totalAmount?.uzs?.toLocaleString()} UZS`}
                          </td>
                          <td className="px-6 py-2 text-gray-800 dark:text-white">
                            {contract.manager.name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerContractModal;
