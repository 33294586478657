import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const csrfToken = localStorage.getItem("csrfToken");

  const handlePhoneChange = (phone, country) => {
    setFormData({ ...formData, phone: `+${phone}` });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const response = await fetch(
        "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();

      if (response.ok && data.success) {
        const currentTime = new Date().getTime();
        const expirationTime = currentTime + 3 * 24 * 60 * 60 * 1000;

        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("loginTime", currentTime);
        localStorage.setItem("expirationTime", expirationTime);
        localStorage.setItem("role", data?.manager?.role);
        localStorage.setItem("name", data?.manager?.name);

        if (data.name) {
          localStorage.setItem("userName", data.name);
        }

        navigate("/");
      } else {
        setErrorMessage(data.message || "Login failed");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const expirationTime = localStorage.getItem("expirationTime");
      const currentTime = new Date().getTime();

      if (expirationTime && currentTime >= expirationTime) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("loginTime");
        localStorage.removeItem("expirationTime");
        console.log("Token muddati tugagan. LocalStorage tozalandi.");
      }
    };

    checkTokenExpiration();
  }, []);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const expirationTime = localStorage.getItem("expirationTime");
      const currentTime = new Date().getTime();

      if (expirationTime && currentTime >= expirationTime) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userName");
        localStorage.removeItem("loginTime");
        localStorage.removeItem("expirationTime");
        console.log("Token muddati tugagan. LocalStorage tozalandi.");
      }
    };

    checkTokenExpiration();
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <>
      <div className="fixed bg-gradient-to-br form-blue-600 to-white rounded-[20px] inset-0 z-50 flex items-center justify-center">
        <div className="relative w-screen h-screen overflow-hidden">
          <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="md:w-[80%] md:h-[500px] lg:max-w-4xl w-full items-center md:items-start h-screen bg-white shadow-lg rounded-lg flex">
              <div className="lg:w-1/2 mx-auto p-10 lg:p-20">
                <img
                  className="mt-4 md:hidden mb-10"
                  src="logo.png"
                  alt="logo login"
                />
                <h2 className="text-2xl hidden md:block font-medium mb-6">
                  Sign In
                </h2>
                <form onSubmit={handleSignIn}>
                  <div className="mb-4">
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    <PhoneInput
                      country={"us"}
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      containerStyle={{ width: "100%" }}
                      inputStyle={{ width: "100%", height: "38px" }}
                      inputClass="w-full py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                      dropdownClass="bg-white border rounded-lg"
                    />
                  </div>
                  <div className="mb-4 relative">
                    <label
                      className="block text-sm font-medium mb-2"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Password"
                      className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                    <button
                      type="button"
                      className="absolute right-3 top-10 text-gray-500 focus:outline-none"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {errorMessage && (
                    <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
                  )}
                  <button
                    type="submit"
                    className="text-center w-full bg-[#36b7fe] text-white py-2 rounded-[7px] mt-7 hover:bg-[#2ca8e6] transition duration-300"
                  >
                    Sign in
                  </button>
                </form>
              </div>
              <div className="w-1/2 hidden h-[500px] bg-gradient-to-tl from-white via-gray-100 to-gray-200 p-10 lg:flex flex-col items-center justify-center rounded-r-xl shadow-lg">
                <div>
                  <img className="mt-4" src="logo.png" alt="logo login" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
