import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function ProductTable({ products, onEditProduct }) {
  const { t } = useTranslation();

  return (
    <div className="overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            <th className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("products.productTable.productTableId")}
            </th>
            <th className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("products.productTable.productTableName")}
            </th>
            {/* <th className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("products.productTable.productTablePurchased")}
            </th>
            <th className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("products.productTable.productTableSold")}
            </th> */}
            <th className="px-6 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("products.productTable.productTableStock")}
            </th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product, index) => (
            <tr key={product._id} className="border-t">
              <td className="px-6 hidden md:block py-2 text-gray-800 dark:text-white">
                {index + 1}
              </td>
              <td className="md:px-2 p-1 text-xs md:text-sm md:py-2 text-gray-800 dark:text-white">
                {product.name}
              </td>
              {/* <td className="px-10 py-2 hidden md:block text-gray-800 dark:text-white">
                {product.purchased?.toLocaleString()}
              </td>
              <td className="md:px-2 p-1 text-xs md:text-sm md:py-2 pr-10 text-gray-800 dark:text-white">
                {product.quantitySold?.toLocaleString()}
              </td> */}
              <td className="md:px-8 p-1 text-xs md:text-sm md:py-2 text-gray-800 dark:text-white">
                {product.stock?.toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
