import { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ContractDeleteModal } from "./contract-delete-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsDot } from "react-icons/bs";
import { useRef } from "react";
import CreatableSelect from 'react-select/creatable'

const ContractModal = ({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedContract,
}) => {
  const [contract, setContract] = useState({
    id: "",
    product: "",
    customerName: "",  // customerName alohida
    phoneNumber: "",   // phoneNumber alohida
    price: { usd: "", uzs: "" },
    quantity: "",
    manager: "",
    contractDate: "",
    comments: [],
  });
  

  const role = localStorage.getItem("role");
  const name = localStorage.getItem("name");

  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerName,setCustomerName] =useState([])
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const [managersData, setManagersData] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [currency, setCurrency] = useState("usd");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { t } = useTranslation();
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api";
  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");
  const userName = localStorage.getItem("name");
  const commentsEndRef = useRef(null);
  const [managers, setManagers] = useState([]);
  const manager = managers.find((manager) => manager?.name === name);
  const manager_id = manager ? manager?._id : null;
  const [count, setCount] = useState(0);
  const [showPhoneInput, setShowPhoneInput] = useState(false); 
  const [phoneNumber, setPhoneNumber] = useState("");

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  const getProducts = async () => {
    try {
      const response = await fetch(`${base_URL}/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "CSRF-Token": csrfToken,
        },
      });
      const data = await response.json();
      if (response.ok) setProducts(data || []);
    } catch (error) {
      console.error("API xato:", error);
    }
  };

  const getCustomers = async () => {
    try {
      const response = await fetch(`${base_URL}/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "CSRF-Token": csrfToken,
        },
      });
      const data = await response.json();
      if (response.ok) setCustomers(data.data || []);
    } catch (error) {
      console.error("API xato:", error);
    }
  };

  const getManagers = async () => {
    try {
      const response = await fetch(`${base_URL}/managers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "CSRF-Token": csrfToken,
        },
      });
      const data = await response.json();
      if (response.ok) setManagers(data.data || []);
    } catch (error) {
      console.error("API xato:", error);
    }
  };

  useEffect(() => {
    getProducts();
    getCustomers();
    getManagers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_URL}/contracts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "CSRF-Token": csrfToken,
          },
        });

        const data = await response.json();

        if (data.success) {
          setProductsData(data.data.map((item) => item));
          setCustomersData(data.data.map((item) => item));
          setManagersData(data.data.map((item) => item));
        } else {
          console.error("API muvaffaqiyatsiz:", data);
        }
      } catch (error) {
        console.error("API xato:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isOpen && contract.comments?.length > 0) {
      scrollToBottom();
    }
  }, [isOpen, contract.comments]);

  useEffect(() => {
    if (isEditMode && selectedContract) {
      setContract({
        id: selectedContract._id,
        product: selectedContract.product || "",
        productName: selectedContract.productName || "",
        customer: selectedContract.customer || "",
        customerName: selectedContract.customerName || "",
        manager: selectedContract.manager || "",
        managerName: selectedContract.managerName || "",
        price: selectedContract.price || { usd: "", uzs: "" }, // Ensure price is an object
        quantity: selectedContract.quantity || "",
        contractDate: selectedContract.contractDate
          ? new Date(selectedContract.contractDate).toISOString().split("T")[0]
          : "",
        comments: selectedContract.comments || [],
      });
      setCurrency(selectedContract.currency || "usd"); 
    } else {
      resetForm();
    }
  }, [isEditMode, selectedContract]);

  const resetForm = () => {
    setContract({
      id: "",
      productId: "",
      productName: "",
      customerName: "",
      customer: "",
      price: { usd: "", uzs: "" },
      quantity: "",
      manager: "",
      managerName: "",
      contractDate: "",
      comments: [],
    });
    setCurrency("usd"); 
  };

  const handleChange = (key, value) => {
    if (key === "price" || key === "quantity") {
      value = Number(value);
    }
    setContract((prev) => ({ ...prev, [key]: value }));
  };

  const handleCurrencyChange = (event) => {
    const selectedValue = event.target.value;
    setCurrency(event.target.value);

    const symbol = selectedValue === "usd" ? "$" : "uzs";
    setContract((prev) => ({ ...prev, priceSymbol: symbol }));
  };

  const currencyOptions = [
    { value: "usd", label: "usd" },
    { value: "uzs", label: "uzs" },
  ];

  useEffect(() => {
    setCurrency("usd");
    setContract((prev) => ({
      ...prev,
      priceSymbol: "$",
      price: prev.price || "",
    }));
  }, []);

  const handleSave = async () => {
    let formattedContract;
  
    if (showPhoneInput) {
      // Agar showPhoneInput active bo'lsa
      formattedContract = {
        productId: contract.product,
        quantity: Number(contract.quantity),
        price: {
          [currency]: contract.price[currency],
        },
        customer: customerName, // Yaratilgan customer nomi
        phoneNumber: phoneNumber, // Telefon raqami
        manager: role === "manager" ? manager_id : contract.manager,
        contractDate: contract.contractDate,
        comments: newComment ? [...contract.comments, newComment] : contract.comments, // faqat bo'sh bo'lmagan comment qo'shiladi
      };
    } else {
      // Agar showPhoneInput active bo'lmasa
      formattedContract = {
        productId: contract.product,
        quantity: Number(contract.quantity),
        price: {
          [currency]: contract.price[currency],
        },
        customer: contract.customer, // Tanlangan customer ID si
        manager: role === "manager" ? manager_id : contract.manager,
        contractDate: contract.contractDate,
        comments: newComment ? [...contract.comments, newComment] : contract.comments, // faqat bo'sh bo'lmagan comment qo'shiladi
      };
    }
  
    try {
      const url = isEditMode
        ? `${base_URL}/contracts/${selectedContract?._id}` // Tahrirlash
        : `${base_URL}/contracts`; // Yangi kontrakt yaratish
  
      const method = isEditMode ? "PATCH" : "POST";
  
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify(formattedContract),
      });
  
      const responseData = await response.json();
      if (response.ok) {
        toast.success("Kontrakt muvaffaqiyatli saqlandi!");
        resetForm();
        onClose();
        window.location.reload()
      } else {
        throw new Error(
          responseData.message || "Kontraktni saqlashda muammo yuz berdi."
        );
      }
    } catch (error) {
      console.error("Kontraktni saqlashda xato:", error);
      toast.error(
        error.message ||
          "Kontraktni saqlashda muammo yuz berdi. Iltimos qayta urinib ko'ring."
      );
    }
  };
  
  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    if (!contract?.id) {
      alert(
        "Xatolik: Kontrakt ID aniqlanmadi. Iltimos, kontraktni saqlang yoki qayta urinib ko‘ring."
      );
      return;
    }

    const timestamp = new Date().toISOString();
    const newCommentObject = {
      text: newComment,
      timestamp,
    };

    try {
      const response = await fetch(`${base_URL}/contracts/${contract.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify({
          comments: [...(selectedContract.comments || []), newCommentObject],
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        console.error("Error response:", responseData);
        throw new Error("Comment qo‘shishda xatolik yuz berdi.");
      }

      setContract((prev) => ({
        ...prev,
        comments: [...(prev.comments || []), newCommentObject],
      }));
      setNewComment("");
    } catch (error) {
      console.error("Xato:", error.message);
      alert("Comment qo‘shishda muammo yuz berdi.");
    }
  };

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${base_URL}/contracts/${selectedContract._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Kontraktni o'chirishda xato.");
      }

      toast.success("Kontrakt muvaffaqiyatli o'chirildi!");
      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Kontraktni o'chirishda xato:", error);
      toast.error(
        "Kontraktni o'chirishda muammo yuz berdi. Iltimos qayta urinib ko'ring."
      );
    }
  };

  if (!isOpen) return null;

  const uniqueProducts = Array.from(
    new Map(products.map((product) => [product._id, product])).values()
  );

  const uniqueCustomers = Array.from(
    new Map(customers.map((customer) => [customer._id, customer])).values()
  );

  const uniqueManagers = Array.from(
    new Map(managers.map((manager) => [manager._id, manager])).values()
  );

  const productOptions = uniqueProducts.map((product) => ({
    value: product._id,
    label: product.name,
  }));

  const customerOptions = uniqueCustomers.map((customer) => ({
    value: customer._id,
    label: customer.name,
  }));

  const managerOptions = uniqueManagers.map((manager) => ({
    value: manager._id,
    label: manager.name,
  }));

  const handleCustomerChange = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      // Yangi mijoz yaratilmoqda
      const newCustomer = { label: newValue.label, value: newValue.value };
      setCustomers((prevCustomers) => [...prevCustomers, newCustomer]); // Customers ro'yxatiga qo'shish
      setSelectedCustomer(newCustomer);
      setShowPhoneInput(true); // Telefon raqami inputini ko'rsatish
      setPhoneNumber(""); // Telefon raqamini tozalash
      setCustomerName(newValue.value); // Contract uchun mijoz nomini o'rnatish
    } else if (actionMeta.action === "select-option") {
      // Mavjud mijoz tanlandi
      setSelectedCustomer(newValue);
      setShowPhoneInput(false); // Telefon raqamini yashirish
      setContract((prev) => ({ ...prev, customer: newValue.value })); // Contract uchun customer ID ni o'rnatish
    } else if (actionMeta.action === "clear") {
      // Tanlov tozalandi
      setSelectedCustomer(null);
      setShowPhoneInput(false); // Telefon raqamini yashirish
      setPhoneNumber(""); // Telefon raqamini tozalash
      setCustomerName(""); // Customer nomini tozalash
    }
  };
  
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-6 text-black dark:text-white">
          {isEditMode
            ? t("contracts.contractsModal.contractEdit")
            : t("contracts.contractsModal.contractAdd")}
        </h2>
        <div className="grid grid-cols-2 gap-4">
       {/* Product Select */}
        <div>
            <label className="dark:text-white">
              {t("contracts.contractsModal.contractProduct")}
            </label>
            <Select
              options={productOptions}
              value={
                isEditMode
                  ? productOptions.find(
                      (option) => option.value === contract.product
                    ) || {
                      label: selectedContract.productName,
                      value: selectedContract.product,
                    }
                  : productOptions.find(
                      (option) => option.value === contract.product
                    ) || null
              }
              onChange={(selectedOption) => {
                handleChange("product", selectedOption?.value || "");
              }}
            />
          </div>

          <div>
  {/* Customer */}
  <label className="dark:text-white">
              {t("contracts.contractsModal.contractCustomer")}
            </label>
  <CreatableSelect
  isClearable
  options={customers.map((customer) => ({
    value: customer._id, 
    label: customer.name,
  }))}
  onChange={handleCustomerChange}
/>
          </div>
  {/* Price */}
  <div>
            <label className="dark:text-white">
              {t("contracts.contractsModal.contractPrice")}
            </label>
            <div className="relative flex items-center">
              <div className="absolute left-2 text-gray-500">
                {currency === "usd" ? "$" : "uzs"}
              </div>
              <input
                type="number"
                value={contract.price[currency] || ""}
                onChange={(e) =>
                  setContract((prev) => ({
                    ...prev,
                    price: {
                      ...prev.price,
                      [currency]: e.target.value, 
                    },
                  }))
                }
                className="w-full pl-10 pr-3 py-2 border rounded-l-md"
              />
              <select
                value={currency}
                onChange={handleCurrencyChange}
                className="px-3 py-[10px] uppercase border-l-0 rounded-r-md bg-gray-50"
              >
                {currencyOptions.map((currencyOption) => (
                  <option
                    key={currencyOption.value}
                    value={currencyOption.value}
                  >
                    {currencyOption.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

  {/* Quantity */}
       <div>
            <label className="dark:text-white">Quantity</label>
            <input
              type="number"
              value={contract.quantity || ""}
              onChange={(e) => handleChange("quantity", e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>

          {/* manager */}
          {role != "manager" && (
            <div>
              <label className="dark:text-white">
                {t("contracts.contractsModal.contractManager")}
              </label>
              <Select
                options={managerOptions}
                value={
                  isEditMode
                    ? managerOptions.find(
                        (option) => option.value === contract.manager
                      ) || {
                        label: selectedContract.managerName,
                        value: selectedContract.manager,
                      }
                    : managerOptions.find(
                        (option) => option.value === contract.manager
                      ) || null
                }
                onChange={(selectedOption) => {
                  handleChange("manager", selectedOption?.value || "");
                }}
              />
            </div>
          )}

          {/* date */}
          <div>
            <label className="dark:text-white">
              {t("contracts.contractsModal.contractDate")}
            </label>
            <input
              type="date"
              value={contract.contractDate || ""}
              onChange={(e) => handleChange("contractDate", e.target.value)}
              className="w-full border p-2 rounded"
            />
          </div>
          {showPhoneInput && (
  <div className="col-span-2">
    <label className="dark:text-white">Phone</label>
    <input
      type="text"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
      className="w-full border p-2 rounded"
    />
  </div>
)}
        </div>

        {/* comment */}
        <div>
          <h3 className="text-lg font-semibold mb-2 dark:text-white">
            {t("leads.leadmodal.commentTitle")}
          </h3>
          <div className="mb-4 max-h-48 overflow-y-auto custom-scrollbar">
            {contract?.comments?.length > 0 ? (
contract.comments.map((comment) => (
  <div
    key={comment.timestamp} 
    className="flex items-start gap-3 p-3 bg-white dark:bg-gray-800 rounded-lg"
  >
    <div
      className="bg-[#BF360C] text-white rounded-full w-[45px] h-10 flex items-center justify-center"
      title={userName}
    >
      <p>{userName ? userName.charAt(0).toUpperCase() : "U"}</p>
    </div>

    <div className="flex flex-col w-full">
      <div className="flex gap-1 items-center">
        <p className="text-sm font-semibold text-gray-800 dark:text-white">
          {userName}
        </p>
        <BsDot className="w-5 h-5 text-gray-400" />
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {new Date(comment.date).toLocaleString()}
        </p>
      </div>
      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
        {comment.text}
      </p>
    </div>
  </div>
))

            ) : (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                No comments
              </p>
            )}
            <div ref={commentsEndRef} />
          </div>

          <div className="flex items-center gap-3 mt-4">
            <textarea
              rows={1}
              type="text"
              value={newComment}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white"
              placeholder={t("leads.leadmodal.commentPlaceholder")}
            />
            {isEditMode ? (
              <button
                onClick={handleAddComment}
                className="bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                {t("leads.leadmodal.commentSend")}
              </button>
            ) : null}
          </div>
        </div>

        {/* actions */}
        <div className="flex justify-between gap-4 my-4">
          {isEditMode && (
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={() => setShowConfirmModal(true)}
            >
              {t("contracts.contractsModal.contractDeleteBTN")}
            </button>
          )}

          <div className="flex gap-2">
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded-md"
              onClick={handleClose}
            >
              {t("contracts.contractsModal.contractCancelBTN")}
            </button>

            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleSave}
            >
              {t("contracts.contractsModal.contractSaveBTN")}
            </button>
          </div>
        </div>

        {showConfirmModal && (
          <ContractDeleteModal
            isOpen={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={handleDelete}
          />
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default ContractModal;
