import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { customers as initialCustomers } from "../constants/customers-list";
import { LeadTable, LeadModal, Search } from "../components";
import LeadFilter from "../components/Leads-components/lead-filter";
import dayjs from "dayjs";
import Loader from "../ui/Loader";
import { showToast } from "./Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Leads({ isDarkMode }) {
  const base_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api";

  const [leadList, setLeadList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [getLeadsData, setGetLeadsData] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [products, setProducts] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [callDates, setCallDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");

  const fetchLeads = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${base_URL}/leads`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
      });

      const data = await response.json();
      const leadsWithFormattedDate = data.data.map((lead) => ({
        ...lead,
        whenToCall: dayjs(lead.whenToCall).format("YYYY-MM-DD HH:mm"),
      }));

      setGetLeadsData(leadsWithFormattedDate);
      setFilteredLeads(leadsWithFormattedDate);
      setProducts([
        ...new Set(leadsWithFormattedDate.map((lead) => lead.product)),
      ]);
      setStatuses([
        ...new Set(leadsWithFormattedDate.map((lead) => lead.status)),
      ]);
      setCallDates([
        ...new Set(
          leadsWithFormattedDate.map((lead) =>
            dayjs(lead.whenToCall).format("YYYY-MM-DD HH:mm")
          )
        ),
      ]);
    } catch (error) {
      console.log("Error fetching leads:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  useEffect(() => {
    const filteredData = getLeadsData.filter((lead) =>
      Object.values(lead).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredLeads(filteredData);
  }, [searchQuery, getLeadsData]);

  const handleEditLead = (lead) => {
    setSelectedLead({ ...lead });
    setIsModalOpen(true);
  };

  const handleSave = (lead) => {
    if (!lead.id) {
      const newLead = { ...lead, id: leadList.length + 1 };
      setLeadList((prevList) => [...prevList, newLead]);
      showToast("success", "Lead added successfully!");
    } else {
      const updatedLeadList = leadList.map((l) =>
        l.id === lead.id ? lead : l
      );
      setLeadList(updatedLeadList);
      showToast("info", "Lead updated successfully!");
    }
    fetchLeads();
    setIsModalOpen(false);
    setSelectedLead(null);
  };

  const handleFilterByCallDate = (isAscending) => {
    const sortedLeads = [...getLeadsData].sort((a, b) => {
      const dateA = dayjs(a.whenToCall).valueOf();
      const dateB = dayjs(b.whenToCall).valueOf();

      return isAscending ? dateA - dateB : dateB - dateA;
    });
    setFilteredLeads(sortedLeads);
  };

  const handleFilterByStatus = (status) => {
    const filtered = getLeadsData.filter((lead) => lead.status === status);
    setFilteredLeads(filtered);
  };

  const handleDelete = (leadId) => {
    const updatedLeadList = leadList.filter((lead) => lead._id !== leadId);
    setLeadList(updatedLeadList);
    fetchLeads();
    setIsModalOpen(false);
    setSelectedLead(null);
    showToast("warning", "Lead deleted successfully!");
  };

  return (
    <div className="max-w-full min-h-screen lg:px-8 lg:ml-14 mx-auto bg-[#FAFAFB] dark:bg-[#1F2937]">
      <ToastContainer />
      <h1 className="xs:ml-3 text-2xl font-bold mb-6 text-black dark:text-white">
        {t("leads.leadsTitle")}
      </h1>
      <div className="bg-white p-4 dark:bg-gray-700 shadow rounded-lg mb-6">
        <div className="flex flex-wrap justify-between items-center gap-4">
          <h2 className="xs:ml-3 text-xl mb-3 lg:mb-0 text-left font-semibold text-black dark:text-white">
            {t("leads.leadsTableName")}
          </h2>
          <div className="xs:ml-4 lg:ml-0">
            <Search
              isDarkMode={isDarkMode}
              onSearch={(query) => setSearchQuery(query)}
            />
          </div>
          <div className="xs:ml-4 lg:ml-0 flex gap-3 my-3 lg:my-0 xs:justify-start lg:mt-4 lg:justify-end lg:mr-3">
            <LeadFilter
              callDates={callDates}
              handleFilterByCallDate={handleFilterByCallDate}
              statuses={statuses}
              handleFilterByStatus={handleFilterByStatus}
            />
            <button
              onClick={() => {
                setIsModalOpen(true);
                setSelectedLead(null);
              }}
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-1"
            >
              <PlusIcon className="w-5 h-5" />
              {t("leads.leadsAdd")}
            </button>
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : filteredLeads.length > 0 ? (
          <div>
            <LeadTable leads={filteredLeads} onEditLead={handleEditLead} />
          </div>
        ) : (
          <p className="text-gray-700 text-center my-10 text-3xl dark:text-gray-300">
            No leads available.
          </p>
        )}
      </div>
      <LeadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isEditMode={Boolean(selectedLead)}
        selectedLead={selectedLead}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </div>
  );
}
