import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";

const TotalProfit = ({ period }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const [totalProfit, setTotalProfit] = useState(0); // Total profit value
  const [animatedProfit, setAnimatedProfit] = useState(0); // Animated profit value

  const fetchProfitData = async (selectedPeriod) => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");
      const currency = localStorage.getItem("currency") || "usd"; // Default to USD

      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/analytics/margin/${selectedPeriod}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch profit data");
      }

      const data = await response.json();
      if (data && data.analytics) {
        setTotalProfit(data.analytics.totalProfit[currency] || 0); // Use selected currency
        formatChartData(data.analytics.profitShare);
      } else {
        setTotalProfit(0);
        setChartData([]);
      }
    } catch (error) {
      console.error("Error fetching profit data:", error);
      setTotalProfit(0);
      setChartData([]);
    }
  };

  const formatChartData = (profitShare) => {
    const formattedData = Object.entries(profitShare || {}).map(
      ([name, details]) => ({
        label: name,
        value: details.percent || 0,
      })
    );
    setChartData(formattedData);
  };

  // Animate the total profit display
  useEffect(() => {
    let start = 0;
    const duration = 2000; // 2 seconds
    const stepTime = 50; // Update every 50ms
    const increment = Math.ceil(totalProfit / (duration / stepTime));

    const interval = setInterval(() => {
      start += increment;
      if (start >= totalProfit) {
        start = totalProfit;
        clearInterval(interval);
      }
      setAnimatedProfit(start);
    }, stepTime);

    return () => clearInterval(interval); // Cleanup interval
  }, [totalProfit]);

  useEffect(() => {
    if (period) {
      fetchProfitData(period);
    }
  }, [period]);

  const colors = [
    "#4a90e2",
    "#5e8bc4",
    "#3b5998",
    "#4a74d0",
    "#66b3ff",
    "#8ab1e4",
    "#0066cc",
    "#3385cc",
    "#00bfff",
    "#1e90ff",
  ];

  const chartDataWithColors = chartData.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));

  return (
    <div className="border rounded-md p-5">
      <div className="flex items-center justify-between gap-5">
        <h3 className="text-lg font-medium my-5 dark:text-white">
          {t("sales.salesProfit")}
        </h3>
      </div>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <div className="relative">
          <PieChart
            series={
              chartData.length > 0
                ? [
                    {
                      paddingAngle: 1,
                      innerRadius: 50,
                      outerRadius: 125,
                      data: chartDataWithColors,
                    },
                  ]
                : [
                    {
                      paddingAngle: 1,
                      innerRadius: 50,
                      outerRadius: 125,
                      data: [{ label: "", value: 1, color: "#f0f0f0" }], // Bo'sh grafik
                    },
                  ]
            }
            margin={{ right: 10 }}
            width={250}
            height={250}
            legend={{ hidden: true }}
          />
<div className="absolute dark:text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold flex items-center gap-1">
  {animatedProfit.toFixed(2)}
  <span>
    {localStorage.getItem("currency") === "usd" ? "$" : "UZS"}
  </span>
</div>

        </div>
      </Stack>
    </div>
  );
};

export default TotalProfit;
