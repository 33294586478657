import React, { useState, useEffect } from "react";
import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import CustomerContarctModal from "./customer-contracts-modal";
import { useTranslation } from "react-i18next";

export default function CustomerTable({
  customers,
  onEditCustomer,
  onDeleteCustomer,
}) {
  const [contractModal, setContractModal] = useState(false);
  const [customer, setCustomer] = useState([]);
  const [currency, setCurrency] = useState("usd");

  const { t } = useTranslation();

  useEffect(() => {
    const storedCurrency = localStorage.getItem("currency") || "usd";
    setCurrency(storedCurrency);
  }, []);

  return (
    <div className="overflow-auto max-h-[60vh] border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            <th
              scope="col"
              className="xs:hidden xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("customers.customerTable.id")}
            </th>
            <th
              scope="col"
              className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("customers.customerTable.name")}
            </th>
            <th
              scope="col"
              className="xs:px-2 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("customers.customerTable.phoneNumber")}
            </th>
            <th
              scope="col"
              className="xs:px-2 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("customers.customerTable.totalSpend")}
            </th>
            <th
              scope="col"
              className="xs:px-3 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("customers.customerTable.contracts")}
            </th>
            <th
              scope="col"
              className="xs:px-3 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-sm font-semibold text-gray-600 dark:text-white text-left"
            >
              {t("customers.customerTable.actions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {customers.map((customer, index) => (
            <tr key={customer._id} className="border-t">
              <td className="xs:hidden xs:pl-4 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {index + 1}
              </td>
              <td className="xs:pl-4 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {customer.name}
              </td>
              <td className="xs:pl-1 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {customer.phoneNumber || "Not provided"}
              </td>
              <td className="xs:pl-1 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                {currency === "usd"
                  ? `$${customer?.totalSpend?.usd?.toLocaleString()}`
                  : `${customer?.totalSpend?.uzs?.toLocaleString()} UZS`}
              </td>
              <td className="xs:pl-3 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                <button
                  onClick={() => {
                    setContractModal(true);
                    setCustomer(customer);
                  }}
                  className="text-green-600 hover:text-green-800 dark:text-green-400"
                >
                  <ClipboardDocumentCheckIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
              <td className="xs:pl-3 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-base">
                <button
                  onClick={() => onEditCustomer(customer)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {contractModal && (
        <CustomerContarctModal onClose={setContractModal} customer={customer} />
      )}
    </div>
  );
}
