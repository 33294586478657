import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-center px-4">
      <h1 className="text-9xl font-extrabold text-gray-800">404</h1>
      <p className="text-2xl font-semibold text-gray-700 mt-4">
        Sorry, we couldn't find this page.
      </p>
      <p className="text-gray-500 mt-2">
        But don't worry, you can find plenty of other things on our homepage.
      </p>
      <button
        onClick={() => navigate("/")}
        className="mt-6 px-6 py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition duration-300"
      >
        Back to homepage
      </button>
    </div>
  );
};

export default NotFound;
