import React, { useState, useEffect, useRef } from "react";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const ContractFilter = ({
  uniqueProducts = [],
  handleFilterByDate,
  handleFilterByProductName,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleSubFilter = (filter) =>
    setActiveFilter((prev) => (prev === filter ? null : filter));

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center gap-2 px-4 py-2 border rounded-md bg-white dark:bg-gray-700 dark:text-white text-black focus:outline-none"
      >
        <FunnelIcon className="w-5 h-5 text-[#37B5FE]" />
      </button>

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute w-64 bg-white dark:bg-gray-700 border rounded-lg shadow-lg z-30 mt-2 
          xs:left-0
          lg:right-0 lg:left-auto"
        >
          <div className="relative">
            <button
              className="w-full text-left px-4 py-2 text-md text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
              onClick={() => toggleSubFilter("date")}
            >
              {t("contracts.fiter.contractFilterBy")}
            </button>
            {activeFilter === "date" && (
              <div>
                <button
                  onClick={() => handleFilterByDate(true)}
                  className="w-full px-8 text-left text-sm text-black dark:text-white py-2 hover:bg-gray-100 dark:hover:bg-gray-500"
                >
                  {t("contracts.fiter.contractFilterByA")}
                </button>
                <button
                  onClick={() => handleFilterByDate(false)}
                  className="w-full px-8 text-left text-sm text-black dark:text-white py-2 hover:bg-gray-100 dark:hover:bg-gray-500"
                >
                  {t("contracts.fiter.contractFilterByD")}
                </button>
              </div>
            )}
          </div>

          {/* Product.name bo'yicha filtr */}
          <div className="relative">
            <button
              className="w-full text-left px-4 py-2 text-md text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
              onClick={() => toggleSubFilter("product")}
            >
              {t("contracts.fiter.contractFilterByName")}
            </button>
            {activeFilter === "product" && (
              <div>
                {uniqueProducts.map((product) => (
                  <div
                    key={product}
                    className="w-full hover:bg-gray-100 dark:hover:bg-gray-500"
                  >
                    <button
                      className="w-full text-left text-sm text-black dark:text-white px-8 py-2"
                      onClick={() => {
                        handleFilterByProductName(product);
                        setActiveFilter(null);
                      }}
                    >
                      {product}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContractFilter;
