import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { FilterDropdown, OrderTable, OrderModal, Search } from "../components";
import { PlusIcon } from "@heroicons/react/20/solid";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

export default function CallDuties({ isDarkMode }) {
  const [orders, setOrders] = useState([]);
  const [sortBy, setSortBy] = useState("Date");
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const apiUrl = "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/leads";

  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        });

        const data = await response.json();
        if (data.success) {
          const ordersData = data.data.map((order) => ({
            ...order,
            formattedDate: dayjs(order.whenToCall).format("YYYY-MM-DD HH:mm"),
          }));

          const currentDate = dayjs();
          const futureOrders = ordersData.filter((order) =>
            dayjs(order.whenToCall).isAfter(currentDate)
          );

          const sortedOrders = futureOrders
            .filter((order) => !order.callDuty)
            .sort((a, b) => dayjs(a.whenToCall).diff(dayjs(b.whenToCall)));

          setOrders(sortedOrders);
          setFilteredOrders(sortedOrders);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleToggleCallDuty = async (id) => {
    const updatedOrders = orders.map((order) =>
      order._id === id ? { ...order, callDuty: true } : order
    );

    setOrders(updatedOrders);
    setFilteredOrders(updatedOrders.filter((order) => !order.callDuty));

    try {
      const response = await fetch(`${apiUrl}/${id}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ callDuty: true }),
      });

      const data = await response.json();

      if (!data.success) {
        const revertOrders = orders.map((order) =>
          order._id === id ? { ...order, callDuty: false } : order
        );

        setOrders(revertOrders);
        setFilteredOrders(revertOrders.filter((order) => !order.callDuty));
        console.error("Failed to update order:", data.message);
      }
    } catch (error) {
      const revertOrders = orders.map((order) =>
        order._id === id ? { ...order, callDuty: false } : order
      );
      setOrders(revertOrders);
      setFilteredOrders(revertOrders.filter((order) => !order.callDuty));
      console.error("Error updating order:", error);
    }
  };

  const handleAddOrder = (savedOrder) => {
    const updatedOrders = [savedOrder, ...orders];
    setOrders(updatedOrders);
    setFilteredOrders(updatedOrders.filter((order) => !order.callDuty));
    setIsOrderModalOpen(false);
    setSelectedOrder(null);
  };

  const handleSelectSort = (option) => {
    setSortBy(option);
    sortOrders(option);
  };

  const handleFilterByStatus = (status) => {
    if (status === "all") {
      const filtered = orders.filter((order) => !order.callDuty);
      setFilteredOrders(filtered);
    } else {
      const filtered = orders.filter(
        (order) => order.status === status && !order.callDuty
      );
      setFilteredOrders(filtered);
    }
  };

  const sortOrders = (sortByOption) => {
    const sortedData = [...filteredOrders];
    if (sortByOption === "Date") {
      sortedData.sort((a, b) => {
        const dateA = dayjs(a.whenToCall);
        const dateB = dayjs(b.whenToCall);
        const currentDate = dayjs();

        const diffA = Math.abs(currentDate.diff(dateA));
        const diffB = Math.abs(currentDate.diff(dateB));

        return diffA - diffB;
      });
    } else if (sortByOption === "Status") {
      const statusOrder = ["contracted", "potential", "interested"];
      sortedData.sort(
        (a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status)
      );
    } else {
      sortedData.sort((a, b) => a.name.localeCompare(b.name));
    }
    setFilteredOrders(sortedData);
  };

  useEffect(() => {
    const filteredData = orders
      .filter((order) => !order.callDuty)
      .filter((order) =>
        Object.values(order).some((value) =>
          value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    setFilteredOrders(filteredData);
  }, [searchQuery, orders]);

  return (
    <div className="max-w-full min-h-screen xs:px-0 lg:px-8 lg:ml-16 mx-auto bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="xs:ml-4 text-2xl font-bold text-left mb-6 text-black dark:text-white">
        Call Duties
      </h1>
      <div className="bg-white dark:bg-gray-700 shadow rounded-lg xs:p-0 p-4 mb-6">
        <div className="flex flex-col lg:flex-row py-2 px-2 lg:justify-between items-start lg:items-center gap-4">
          <h2 className="xs:ml-4 text-xl font-semibold text-black dark:text-white lg:ml-3">
            {t("dashboard.dashboardTableTitle")}
          </h2>
          <div className="max-w-[500px]">
            <Search
              isDarkMode={isDarkMode}
              onSearch={(query) => setSearchQuery(query)}
            />
          </div>
          <div className="flex gap-4">
            <FilterDropdown
              sortBy={sortBy}
              handleSelect={handleSelectSort}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              handleFilterByStatus={handleFilterByStatus}
            />
          </div>
        </div>

        {isLoading ? (
          <Loader />
        ) : filteredOrders && filteredOrders.length > 0 ? (
          <div className="xs:w-screen lg:w-full">
            <OrderTable
              orders={filteredOrders}
              handleToggleCallDuty={handleToggleCallDuty}
            />
          </div>
        ) : (
          <p className="ml-3  text-gray-700 dark:text-gray-300">
            {t("dashboard.dashboardNoData")}
          </p>
        )}
      </div>

      {/* <OrderModal
    isOpen={isOrderModalOpen}
    onClose={() => setIsOrderModalOpen(false)}
    onSave={handleAddOrder}
    selectedOrder={selectedOrder}
  /> */}
    </div>
  );
}
