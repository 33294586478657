import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";

const TotalProfit = ({ period }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const [products, setProducts] = useState([]);
  const [totalProfit, setTotalProfit] = useState(0);
  const [animatedProfit, setAnimatedProfit] = useState(0);

  // Fetch products from API
  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");

      const response = await fetch(
        "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/products",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }

      const data = await response.json();
      const filteredProducts = data.filter(
        (product) => product.quantitySold >= 1
      );
      setProducts(filteredProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Fetch profit data based on selected period
  const fetchProfitData = async (selectedPeriod) => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");
      const currency = localStorage.getItem("currency") || "usd";

      const productIds = products.map((product) => product._id).join(",");
      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/analytics/margin/${selectedPeriod}?productIds=${productIds}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch profit data");
      }

      const data = await response.json();
      if (data && data.analytics) {
        setTotalProfit(data.analytics.totalProfit[currency] || 0);
      } else {
        setTotalProfit(0);
        setChartData([]);
      }
    } catch (error) {
      console.error("Error fetching profit data:", error);
      setTotalProfit(0);
      setChartData([]);
    }
  };

  // Animate profit data
  const animateProfit = (value) => {
    let count = 0;
    const interval = setInterval(() => {
      count += Math.ceil(value / 50); // Increase value in small steps
      setAnimatedProfit(Math.min(count, value));

      if (count >= value) {
        clearInterval(interval);
      }
    }, 10); // Update every 50ms
  };

  // useEffect hooks
  useEffect(() => {
    if (products.length > 0) {
      fetchProfitData(period); // Fetch profit data when products are available
    }
  }, [products, period]);

  useEffect(() => {
    fetchProducts(); // Fetch products on initial render
  }, []);

  useEffect(() => {
    animateProfit(totalProfit); 
  }, [totalProfit]);

  const colors = ["#28a745", "#f39c12", "#3498db", "#e74c3c", "#9b59b6"];
  
  const chartDataWithColors = totalProfit > 0 
    ? [
        {
          label: `${t("sales.salesProfit")} (${localStorage.getItem("currency") === "usd" ? "$" : "UZS"})`,
          value: animatedProfit, 
          color: colors[0],
        }
      ]
    : [{ label: "No Data", value: 1, color: "#f0f0f0" }];

  return (
    <div className="border rounded-md p-5">
      <div className="flex items-center justify-between gap-5">
        <h3 className="text-lg font-medium my-5 dark:text-white">
          {t("sales.salesProfit")}
        </h3>
      </div>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <div className="relative">
          <PieChart
            series={[
              {
                paddingAngle: 1,
                innerRadius: 50,
                outerRadius: 125,
                data: chartDataWithColors,
              },
            ]}
            margin={{ right: 10 }}
            width={250}
            height={250}
            legend={{ hidden: true }}
          />
          <div className="absolute dark:text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold flex items-center gap-1">
            {animatedProfit.toFixed(2)}
            <span>{localStorage.getItem("currency") === "usd" ? "$" : "UZS"}</span>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default TotalProfit;
