import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  CallDuties,
  Contracts,
  Conversions,
  Customers,
  Dashboard,
  Leads,
  Managers,
  Purchases,
  StockProducts,
} from "./ui";
import Login from "./ui/Login";
import NotFound from "./ui/Not-found";
import Sales from "./ui/Sales";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
      />
      <Route
        path="/login"
        element={isAuthenticated ? <Navigate to="/" /> : <Login />}
      />
      <Route path="/callDuties" element={<CallDuties />} />
      <Route path="/leads" element={<Leads />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/contracts" element={<Contracts />} />
      <Route path="/products" element={<StockProducts />} />
      <Route path="/managers" element={<Managers />} />
      <Route path="/purchases" element={<Purchases />} />
      <Route path="/conversions" element={<Conversions />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
