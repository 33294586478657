import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { CustomerModal, CustomerTable, Search } from "../components";
import Loader from "../ui/Loader";
import { showToast } from "./Toast";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

const BASE_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api";

export default function Customers({ isDarkMode }) {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");

  const { t } = useTranslation();

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(`${BASE_URL}/customers`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
      });
      const data = await res.json();

      if (Array.isArray(data)) {
        setCustomers(data);
      } else if (data && Array.isArray(data.data)) {
        setCustomers(data.data);
      } else {
        console.error("Unexpected data structure:", data);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Contracts Fetch Error:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (Array.isArray(customers)) {
      const filtered = customers.filter((customer) => {
        const matchesSearch = Object.values(customer || {})
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const matchesFilter =
          selectedItem === "" || customer.name === selectedItem;
        return matchesSearch && matchesFilter;
      });
      setFilteredCustomers(filtered);
    } else {
      console.error("customers is not an array:", customers);
    }
  }, [customers, searchQuery, selectedItem]);

  const handleSave = (updatedCustomer) => {
    setCustomers((prev) => {
      const index = prev.findIndex((c) => c.id === updatedCustomer.id);
      if (index >= 0) {
        const newCustomers = [...prev];
        newCustomers[index] = updatedCustomer;
        showToast("info", "Customer updated successfully!");
        return newCustomers;
      }
      showToast("success", "Customer added successfully!");
      return [...prev, updatedCustomer];
    });
    setIsCustomerModalOpen(false);
  };

  const handleRemoveCustomer = async (id) => {
    fetchCustomers();
    try {
      await fetch(`${BASE_URL}/customers/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
      });
      setCustomers((prev) => prev.filter((customer) => customer.id !== id));
      showToast("warning", "Customer deleted successfully!");
    } catch (error) {
      showToast("error", "Error deleting customer!");
      console.error("Error deleting customer:", error);
    } finally {
      setIsCustomerModalOpen(false);
      window.location.reload(); 
    }
  };

  return (
    <div className="max-w-full lg:ml-14 min-h-screen px-8 bg-[#FAFAFB] dark:bg-[#1F2937]">
      <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
        {t("customers.customerTitle")}
      </h1>
      <div className="bg-white p-4 dark:bg-gray-700 shadow rounded-lg mb-6">
        <div className="flex flex-wrap justify-between items-center gap-5 mb-4">
          <h2 className="xs:py-2 text-xl font-semibold text-black dark:text-white">
            {t("customers.customersTableName")}
          </h2>
          <div className="max-w-[80%]">
            <Search
              isDarkMode={isDarkMode}
              onSearch={(query) => setSearchQuery(query)}
            />
          </div>
          <button
            onClick={() => {
              setIsCustomerModalOpen(true);
              setSelectedCustomer(null);
            }}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md flex items-center gap-1"
          >
            <PlusIcon className="w-5 h-5" />
            {t("customers.customerAddButton")}
          </button>
        </div>
        <ToastContainer />
        {isLoading ? (
          <Loader />
        ) : filteredCustomers.length > 0 ? (
          <div>
            <CustomerTable
              customers={filteredCustomers}
              onEditCustomer={(customer) => {
                setSelectedCustomer(customer);
                setIsCustomerModalOpen(true);
              }}
              onRemoveCustomer={handleRemoveCustomer}
            />
          </div>
        ) : (
          <p className="pl-3 text-gray-700 dark:text-gray-300">
            {t("customers.customerNoData")}
          </p>
        )}
      </div>

      {isCustomerModalOpen && (
        <CustomerModal
          onDelete={handleRemoveCustomer}
          isOpen={isCustomerModalOpen}
          onClose={() => setIsCustomerModalOpen(false)}
          isEditMode={Boolean(selectedCustomer)}
          selectedCustomer={selectedCustomer}
          onSave={handleSave}
        />
      )}
    </div>
  );
}
