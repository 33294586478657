import React, { useState, useEffect, useRef } from "react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const LeadFilter = ({
  callDates = [],
  handleFilterByCallDate,
  statuses = [],
  handleFilterByStatus,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);

  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const toggleSubFilter = (filter) =>
    setActiveFilter((prev) => (prev === filter ? null : filter));

  const sortCallDates = (isAscending) => {
    handleFilterByCallDate(isAscending);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex items-center gap-2 px-4 py-2 border rounded-md bg-white dark:bg-gray-700 dark:text-white text-black focus:outline-none"
      >
        <FunnelIcon className="w-5 h-5 text-[#37B5FE]" />
      </button>

      {isDropdownOpen && (
        <div
  ref={dropdownRef}
  className="absolute w-64 bg-white dark:bg-gray-700 border rounded-lg shadow-lg z-30 mt-2 
    xs:left-0
    lg:right-0 lg:left-auto"
>

          <div className="relative">
            <button
              onClick={() => toggleSubFilter("WhenToCall")}
              className="flex justify-between w-full px-4 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500 border-b"
            >
              {t("leads.leadFilter.whenToCall")}
              <ChevronDownIcon
                className={`w-5 h-5 transition-transform ${
                  activeFilter === "WhenToCall" ? "rotate-180" : ""
                }`}
              />
            </button>
            {activeFilter === "WhenToCall" && (
              <div className="flex flex-col gap-1 px-4 py-2">
                <button
                  onClick={() => sortCallDates(true)}
                  className="block w-full text-left text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 py-2"
                >
                  {t("leads.leadFilter.nearestToCurrentDate")}
                </button>
                <button
                  onClick={() => sortCallDates(false)}
                  className="block w-full text-left text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 py-2"
                >
                  {t("leads.leadFilter.farthestFromCurrentDate")}
                </button>
              </div>
            )}
          </div>

          <div className="relative">
            <button
              onClick={() => toggleSubFilter("Status")}
              className="flex justify-between w-full px-4 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
            >
              {t("leads.leadFilter.status")}
              <ChevronDownIcon
                className={`w-5 h-5 transition-transform ${
                  activeFilter === "Status" ? "rotate-180" : ""
                }`}
              />
            </button>
            {activeFilter === "Status" && (
              <div className="flex flex-col gap-1 px-4 py-2">
                {statuses.map((status, index) => (
                  <button
                    key={index}
                    onClick={() => handleFilterByStatus(status)}
                    className="block w-full text-left text-black dark:text-white hover:bg-gray-200 dark:hover:bg-gray-600 py-2"
                  >
                    {status}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadFilter;
