import React from "react";
import { useTranslation } from "react-i18next";

export default function OrderStatus({ status }) {
  const { t } = useTranslation();

  const orderStatusStyles = {
    contracted:
      "text-green-600 border border-green-600 rounded-full xs:w-16 w-24 text-center xs:px-1 xs:py-1 lg:px-4 lg:py-2 xs:text-[10px] lg:text-base overflow-hidden truncate",
    interested:
      "text-red-600 border border-red-600 rounded-full xs:w-16 w-24 text-center xs:px-1 xs:py-0 lg:px-4 lg:py-2 xs:text-[10px] lg:text-base overflow-hidden truncate",
    potential:
      "text-orange-600 border border-orange-600 rounded-full xs:w-16 w-24 text-center xs:px-1 xs:py-0 lg:px-4 lg:py-2 xs:text-[10px] lg:text-base overflow-hidden truncate",
    purchased:
      "text-blue-700 border border-blue-700 rounded-full xs:w-16 w-24 text-center xs:px-1 xs:py-0 xs:py-0 lg:px-4 lg:py-2 xs:text-[10px] lg:text-base overflow-hidden truncate",
    offered:
      "text-purple-600 border border-purple-600 rounded-full xs:w-16 w-24 text-center xs:px-1 xs:py-0 lg:px-4 lg:py-2 xs:text-[10px] lg:text-base overflow-hidden truncate",
  };
  

  return (
    <span className={orderStatusStyles[status]}>{t(`status.${status}`)}</span>
  );
}
