import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";

const TotalSales = ({ selectedPeriod }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const [animatedAmount, setAnimatedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);
  const [currency, setCurrency] = useState("usd");

  // Fetch data from the API
  const fetchProfitData = async (selectedPeriod) => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");

      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/analytics/margin/${selectedPeriod}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        formatChartData(data.analytics.totalSales); // Format the sales data
      } else {
        setError(t("sales.errorLoadingData"));
      }
    } catch (error) {
      setError(t("sales.errorLoadingData"));
    } finally {
      setLoading(false);  // Ma'lumotlar yuklanganidan keyin loadingni false qiling
    }
  };

  // Format the sales data
// Format the sales data
const formatChartData = (sales) => {
  const fetchedCurrency = localStorage.getItem("currency") || "usd"; 
  setCurrency(fetchedCurrency); // Set the currency value here
  let totalSalesAmount = 0;

  const formattedData = Object.values(sales).map((contract) => {
    const amount = contract[fetchedCurrency] || 0; 
    totalSalesAmount += amount;
    return {
      label: contract.name,
      value: amount,
    };
  });

  setTotalAmount(totalSalesAmount);
  setChartData(formattedData);
};



  // Animate the total sales amount display
  useEffect(() => {
    let start = 0;
    const end = totalAmount || 0;
    const duration = 2000;
    const stepTime = 50;
    const increment = Math.ceil(end / (duration / stepTime));

    const interval = setInterval(() => {
      start += increment;
      if (start >= end) {
        start = end;
        clearInterval(interval);
      }
      setAnimatedAmount(start);
    }, stepTime);

    return () => clearInterval(interval); // Cleanup interval
  }, [totalAmount]);

  // Fetch profit data when component mounts or period changes
  useEffect(() => {
    fetchProfitData(selectedPeriod);
  }, [selectedPeriod]);

  const colors = [
    "#4a90e2",
    "#5e8bc4",
    "#3b5998",
    "#4a74d0",
    "#66b3ff",
    "#8ab1e4",
    "#0066cc",
    "#3385cc",
    "#00bfff",
    "#1e90ff",
  ];

  const chartDataWithColors = chartData.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));

  const renderContent = () => {
    if (error) {
      return (
        <div className="text-center dark:text-white text-lg font-semibold">
          {error}
        </div>
      );
    }

    if (chartData.length === 0 || totalAmount === 0) {
      return (
        <div className="relative">
          <PieChart
            series={[{
              paddingAngle: 1,
              innerRadius: 50,
              outerRadius: 125,
              data: [{ label: "", value: 1, color: "#f0f0f0" }],
            }]}
            margin={{ right: 10 }}
            width={250}
            height={250}
            legend={{ hidden: true }}
          />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold dark:text-white">
           0.00  {currency === "usd" ? "$" : "UZS"}
          </div>
        </div>
      );
    } else {
      return (
        <div className="relative">
          <PieChart
            series={[{
              paddingAngle: 1,
              innerRadius: 50,
              outerRadius: 125,
              data: chartDataWithColors,
            }]}
            margin={{ right: 10 }}
            width={250}
            height={250}
            legend={{ hidden: true }}
          />
<div className="absolute dark:text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold flex items-center gap-1">
  {animatedAmount.toFixed(2)}
  <span>
  {currency === "usd" ? "$" : "UZS"}
  </span>
</div>

        </div>
      );
    }
  };

  return (
    <div className="border rounded-md p-5">
      <div className="flex items-center justify-between gap-5">
        <h3 className="text-lg font-medium my-5 dark:text-white">
          {t("sales.salesContracts")}
        </h3>
      </div>
      <Stack direction="row" alignItems="center" justifyContent="center">
        {/* Remove loading state from here */}
        {renderContent()}
      </Stack>
    </div>
  );
};

export default TotalSales;
