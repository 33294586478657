import React from "react";

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-[400px] bg-gray-100 dark:bg-gray-700">
      <div class="three-body">
        <div class="three-body__dot"></div>
        <div class="three-body__dot"></div>
        <div class="three-body__dot"></div>
      </div>
    </div>
  );
};

export default Loader;
