import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navbar, Sidebar } from "./ui";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsEN from "./locale/translationsEN";
import translationsUZ from "./locale/translationsUZ";
import translationsRU from "./locale/translationsRU";
import Login from "./ui/Login";
import App from "./App";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationsEN },
    uz: { translation: translationsUZ },
    ru: { translation: translationsRU },
  },
  lng: "uz",
  fallbackLng: "uz",
});

export default function Layout() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(
          "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/get-csrf-token"
        );
        if (response.ok) {
          const data = await response.json();
          localStorage.setItem("csrfToken", data.csrfToken);
        } else {
          console.error("Failed to fetch CSRF token");
        }
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCsrfToken();

    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      const isDark = savedTheme === "dark";
      setIsDarkMode(isDark);
    }

    const savedLang = localStorage.getItem("language");
    if (savedLang) {
      i18n.changeLanguage(savedLang);
    }

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsAuthenticated(true);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("theme", newMode ? "dark" : "light");
      return newMode;
    });
  };

  const changeLang = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
  };

  const isNotFoundPage = location.pathname === "*";

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className={`flex flex-col lg:flex-row ${isDarkMode ? "dark" : ""}`}>
      {!isNotFoundPage && (
        <>
          <div className="hidden lg:flex lg:w-72 lg:min-h-screen">
            <Sidebar isDarkMode={isDarkMode} />
          </div>
          <div className="flex-1">
            <Navbar
              changeLang={changeLang}
              isDarkMode={isDarkMode}
              toggleTheme={toggleTheme}
            />
          </div>
        </>
      )}

      <main className="lg:w-full bg-[#FAFAFB] dark:bg-gray-900 min-h-screen mt-20">
        <App />
      </main>

      {/* <div className="block sm:h-7 lg:hidden lg:min-h-screen">
        <Sidebar isDarkMode={isDarkMode} />
      </div> */}
    </div>
  );
}
