import "boxicons/css/boxicons.min.css";

const navigationLinks = [
  {
    display_name: "Dashboard",
    route: "/",
    icon: "bx bxs-home",
    role: "manager",
  },
  {
    display_name: "CallDuties",
    route: "/callDuties",
    icon: "bx bxs-phone-call",
    role: "manager",
  },
  {
    display_name: "Leads",
    route: "/leads",
    icon: "bx bxs-user",
    role: "manager",
  },
  {
    display_name: "Customers",
    route: "/customers",
    icon: "bx bxs-user-rectangle",
    role: "manager",
  },
  {
    display_name: "Contracts",
    route: "/contracts",
    icon: "bx bx-clipboard",
    role: "manager",
  },
  {
    display_name: "StockProducts",
    route: "/products",
    icon: "bx bxs-package",
    role: "manager",
  },
  {
    display_name: "Managers",
    route: "/managers",
    icon: "bx bxs-group",
    role: "director",
  },
  {
    display_name: "Purchases",
    route: "/purchases",
    icon: "bx bxs-receipt",
    role: "manager",
  },
  {
    display_name: "Conversions",
    route: "/conversions",
    icon: "bx bx-filter-alt",
    role: "manager",
  },
  {
    display_name: "Sales",
    route: "/sales",
    icon: "bx bx-dollar",
    role: "director",
  },
];

export default navigationLinks;
