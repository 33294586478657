import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { BsDot } from "react-icons/bs";
import CustomerConfirmModal from "./customer-confirm-modal";

export default function CustomerModal({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  selectedCustomer,
  onDelete,
}) {
  const { t } = useTranslation();
  const commentsEndRef = useRef(null);
  const [confirm, setConfirm] = useState(false);
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "usd");
  const [customerData, setCustomerData] = useState({
    name: "",
    phoneNumber: "",
    quantityPurchased: "",
    comments: [],
  });
  const [newComment, setNewComment] = useState("");

  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");
  const userName = localStorage.getItem("userName");
  const [count, setCount] = useState(0);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    if (isEditMode && selectedCustomer) {
      setCustomerData(selectedCustomer);
    } else {
      resetForm();
    }
  }, [isEditMode, selectedCustomer]);

  const resetForm = () => {
    setCustomerData({
      name: "",
      phoneNumber: "",
      quantityPurchased: "",
      comments: [],
    });
    setNewComment("");
  };

  const handleChange = (key, value) => {
    setCustomerData((prevData) => ({ ...prevData, [key]: value }));
  };

  const handleAddComment = () => {
    if (!newComment.trim()) return;
    setCustomerData((prevData) => ({
      ...prevData,
      comments: [
        ...prevData.comments,
        {
          text: newComment,
          timestamp: new Date().toISOString(),
        },
      ],
    }));
    setNewComment("");
  };

  const handleInputChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSave = async () => {
    if (!customerData.name.trim() || !customerData.phoneNumber.trim()) {
      alert("Name va telefon raqamini to‘ldiring.");
      return;
    }

    try {
      const commentsToSave = isEditMode
        ? customerData.comments
        : [
            ...customerData.comments,
            { text: newComment, timestamp: new Date().toISOString() },
          ];

      const formattedCustomer = {
        name: customerData.name,
        phoneNumber: customerData.phoneNumber,
        quantityPurchased: customerData.quantityPurchased,
        comments: commentsToSave,
      };

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "CSRF-Token": csrfToken,
      };

      let response;
      if (isEditMode) {
        response = await fetch(
          `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/customers/${customerData._id}`,
          {
            method: "PATCH",
            headers,
            body: JSON.stringify(formattedCustomer),
          }
        );
      } else {
        response = await fetch(
          `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/customers`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(formattedCustomer),
          }
        );
      }

      if (!response.ok) {
        throw new Error(
          isEditMode
            ? "Failed to update the customer"
            : "Failed to create a new customer"
        );
      }

      const customer = await response.json();
      onSave(customer);
      resetForm();
      onClose();

      setNewComment("");
      window.location.reload();
    } catch (error) {
      console.error("Error saving customer:", error.message);
      alert("Failed to save customer. Please try again.");
    }
  };

  useEffect(() => {
    if (isOpen && customerData.comments.length > 0) {
      scrollToBottom();
    }
  }, [isOpen, customerData.comments]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4 text-black dark:text-white">
          {isEditMode
            ? t("customers.customerModal.editCustomer")
            : t("customers.customerModal.addCustomer")}
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("customers.customerModal.name")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={customerData.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div>
            <label className="block text-gray-600 dark:text-gray-300 text-sm">
              {t("customers.customerModal.phone")}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-md"
              value={customerData.phoneNumber}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
            />
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2 dark:text-white">
            {t("leads.leadmodal.commentTitle")}
          </h3>
          <div className="mb-4 max-h-48 overflow-y-auto custom-scrollbar">
            {customerData.comments.length > 0 ? (
              <>
                {customerData.comments.map((comment, index) => (
                  <div
                    key={index}
                    className="flex items-start gap-3 p-3 bg-white dark:bg-gray-800 rounded-lg"
                  >
                    <div
                      className="bg-[#BF360C] text-white rounded-full w-[45px] h-10 flex items-center justify-center"
                      title={userName ? userName : "User"}
                    >
                      <p>{userName ? userName.charAt(0).toUpperCase() : "U"}</p>
                    </div>

                    <div className="flex flex-col w-full">
                      <div className="flex gap-1 items-center">
                        <p className="text-sm font-semibold text-gray-800 dark:text-white">
                          {userName}
                        </p>
                        <BsDot className="w-5 h-5 text-gray-400" />
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          {new Date(comment.timestamp).toLocaleString()}
                        </p>
                      </div>
                      <p className="text-sm text-gray-600 dark:text-gray-300 mt-1">
                        {comment.text}
                      </p>
                    </div>
                  </div>
                ))}
                <div ref={commentsEndRef}></div>
              </>
            ) : (
              <p className="text-sm text-gray-500 dark:text-gray-400">
                No comments
              </p>
            )}
          </div>

          <div className="flex items-center gap-3 my-4">
            <textarea
              rows={1}
              type="text"
              value={newComment}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white"
              placeholder={t("leads.leadmodal.commentPlaceholder")}
            />
            {isEditMode && (
              <button
                onClick={handleAddComment}
                className="bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                {t("leads.leadmodal.commentSend")}
              </button>
            )}
          </div>
        </div>

        <div className="flex justify-between space-x-3">
          {isEditMode && (
            <button
              onClick={() => setConfirm(true)}
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
            >
              {t("customers.customerModal.delete")}
            </button>
          )}
          {confirm && (
            <CustomerConfirmModal
              onClose={() => setConfirm(false)}
              onDelete={() => onDelete(customerData?._id)}
            />
          )}
          <div className="flex gap-2">
            <button
              onClick={() => {
                onClose();
                setCount(0);
              }}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 text-black rounded-md"
            >
              {t("customers.customerModal.close")}
            </button>
            {count == 0 ? (
              <button
                onClick={() => {
                  handleSave();
                  setCount(count + 1);
                }}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md"
              >
                {t("customers.customerModal.save")}
              </button>
            ) : (
              <button className="px-4 py-2 bg-blue-400 hover:bg-blue-500 text-white rounded-md">
                {t("customers.customerModal.save")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
