import React, { useState, useEffect, useRef } from "react";
import Loader from "../../ui/Loader";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/20/solid";

export default function SalesModal({ manager, onClose, onSave }) {
  const [contracts, setContracts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);

  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [activeSubFilter, setActiveSubFilter] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");

      try {
        if (manager && manager._id) {
          const response = await fetch(
            `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/managers/${manager._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "CSRF-Token": csrfToken,
              },
            }
          );
          const data = await response.json();

          if (data.success && data.data.contracts) {
            setContracts(data.data.contracts);
            setFilteredContracts(data.data.contracts);
          }
        }
      } catch (error) {
        console.error("Error fetching sales data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [manager]);

  const handleFilterByProduct = (e) => {
    const selectedProduct = e.target.value;
    setSelectedProduct(selectedProduct);
    const filtered = selectedProduct
      ? contracts.filter((contract) => contract.productName === selectedProduct)
      : contracts;
    setFilteredContracts(filtered);
  };

  const handleSortByDate = (order) => {
    setSortOrder(order);
    const sortedContracts = [...filteredContracts].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
    setFilteredContracts(sortedContracts);
  };

  const toggleFilter = (filter) => {
    setSelectedFilter((prev) => (prev === filter ? null : filter));
    setActiveSubFilter(null);
  };

  const toggleSubFilter = (subFilter) => {
    setActiveSubFilter((prev) => (prev === subFilter ? null : subFilter));
  };

  const sortedDatesAsc = t("contracts.fiter.contractFilterByA");
  const sortedDatesDesc = t("contracts.fiter.contractFilterByD");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSelectedFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      onClick={() => onClose(false)}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white dark:bg-gray-800 rounded-lg w-[90%] p-2 md:p-6 md:w-[800px]"
      >
        <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
          {t("managers.managersContractsModal.managerContractsTitle")}
        </h2>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex justify-between items-center mb-4">
              <div className="w-full text-xs">
                <label
                  htmlFor="product"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  {t(
                    "managers.managersContractsModal.managerContractsFilterTitle"
                  )}
                </label>
                <select
                  id="product"
                  value={selectedProduct}
                  onChange={handleFilterByProduct}
                  className="mt-1 block px-2 py-3 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                >
                  <option value="">
                    {t(
                      "managers.managersContractsModal.managerContractsFilterSelect"
                    )}
                  </option>
                  {contracts.map((contract, index) => (
                    <option key={index} value={contract.productName}>
                      {contract.productName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="ml-4 relative mt-6">
                <button
                  className="flex items-center px-3 py-2 border rounded-md text-black dark:text-white focus:outline-none"
                  onClick={() => toggleFilter("Filter")}
                >
                  <div className="flex text-xs items-center gap-2">
                    <span>{t("contracts.fiter.contractFilterTitle")}</span>
                    <FunnelIcon className="w-5 h-5 text-[#37B5FE]" />
                  </div>
                </button>

                {selectedFilter === "Filter" && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-0 mt-2 w-60 bg-white dark:bg-slate-700 border rounded-md shadow-lg z-10"
                  >
                    <div className="relative">
                      <button
                        className="flex justify-between w-full px-4 py-2 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500 border-b"
                        onClick={() => toggleSubFilter("Date")}
                      >
                        {t("contracts.fiter.contractFilterBy")}
                        <ChevronDownIcon
                          className={`w-5 h-5 transition-transform ${
                            activeSubFilter === "Date" ? "rotate-180" : ""
                          }`}
                        />
                      </button>
                      {activeSubFilter === "Date" && (
                        <div className="max-h-48 overflow-y-auto">
                          <button
                            onClick={() => handleSortByDate("asc")}
                            className="text-sm block w-full font-medium text-left px-10 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
                          >
                            {sortedDatesAsc}
                          </button>
                          <button
                            onClick={() => handleSortByDate("desc")}
                            className="text-sm block w-full font-medium text-left px-10 py-3 text-black dark:text-white hover:bg-gray-100 dark:hover:bg-gray-500"
                          >
                            {sortedDatesDesc}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="overflow-x-auto h-[250px] scrollbar-hide mb-4">
              <table className="w-full table-auto border-collapse text-gray-800 dark:text-white">
                <thead className="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    {[
                      t(
                        "managers.managersContractsModal.managerContractTableCustomer"
                      ),
                      t(
                        "managers.managersContractsModal.managerContractTableDate"
                      ),
                      t(
                        "managers.managersContractsModal.managerContractTablePrice"
                      ),
                      t(
                        "managers.managersContractsModal.managerContractTableProduct"
                      ),
                      t(
                        "managers.managersContractsModal.managerContractTableQuantity"
                      ),
                      t(
                        "managers.managersContractsModal.managerContractTableTotal"
                      ),
                    ].map((heading) => (
                      <th
                        key={heading}
                        className="p-2 text-xs md:px-4 md:py-2 md:text-sm font-semibold text-gray-600 dark:text-gray-300 text-left"
                      >
                        {heading}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredContracts.length > 0 ? (
                    filteredContracts.map((contract, index) => (
                      <tr key={index} className="border-t dark:border-gray-600">
                        <td className="md:px-4 md:py-2 p-2 text-xs md:text-base">
                          {contract.customerName}
                        </td>
                        <td className="md:px-4 md:py-2 p-2 text-xs md:text-base">
                          {contract.createdAt
                            ? new Date(contract.createdAt).toLocaleDateString()
                            : "N/A"}
                        </td>
                        <td className="md:px-4 md:py-2 p-2 text-xs md:text-base">
                          $ ${contract.price?.usd?.toLocaleString()}
                        </td>
                        <td className="md:px-4 md:py-2 p-2 text-xs md:text-base">
                          {contract.productName}
                        </td>
                        <td className="md:px-4 md:py-2 p-2 text-xs md:text-base">
                          {contract.quantity}
                        </td>
                        <td className="md:px-4 md:py-2 p-2 text-xs md:text-base">
                          $ {contract.totalAmount?.usd?.toLocaleString()}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="6"
                        className="px-4 py-2 text-center text-gray-600 dark:text-gray-300"
                      >
                        {t(
                          "managers.managersContractsModal.managerContractTableNoData"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
          <div>
            <h3 className="text-black mb-3 md:mb-0 dark:text-gray-300">
              {t("managers.managersContractsModal.managerTotalSales")}
              <span className="text-blue-500 ml-2">
                $
                {new Intl.NumberFormat("en-US", {
                  style: "decimal",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(
                  contracts.reduce(
                    (total, contract) =>
                      total + (contract.totalAmount?.usd || 0),
                    0
                  )
                )}
              </span>
            </h3>
          </div>
          <div className="space-x-4 ml-auto">
            <button
              className="px-4 py-2 text-sm bg-gray-500 hover:bg-gray-400 text-white rounded-lg"
              onClick={onClose}
            >
              {t("managers.managersContractsModal.managerContractTableClose")}
            </button>
            <button
              className="px-4 py-2 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded-lg"
              onClick={onSave}
            >
              {t("managers.managersContractsModal.managerContractTableSave")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
