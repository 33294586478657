const translationsEN = {
  sideBar: {
    Dashboard: "Dashboard",
    CallDuties: "Call Duties",
    Leads: "Leads",
    Customers: "Customers",
    Contracts: "Contracts",
    StockProducts: "Stock Products",
    Managers: "Managers",
    Purchases: "Purchases",
    Conversions: "Conversions",
    Sales: "Sales",
  },
  search: {
    searchPlaceholder: "Search...",
  },
  filter: {
    filterTitle: "Filter by Status",
    filterName: "All Status",
    status: {
      contracted: "Contracted",
      interested: "Interested",
      potential: "Potential",
      purchased: "Purchased",
      offered: "Offered",
    },
  },
  status: {
    contracted: "Contracted",
    interested: "Interested",
    potential: "Potential",
    purchased: "Purchased",
    offered: "Offered",
  },
  dashboard: {
    dashboardTitle: "Dashboard",
    dashboardTableTitle: "Call Duties",
    dashboardNoData: "No data available",
    table: {
      name: "Name",
      phoneNumber: "Phone Number",
      status: "Status",
      whenToCall: "When to Call",
      noScheduleSet: "No schedule set",
    },
  },

analytics: {
    Leads:"Leads",
    Customers: "Customers",
    ContractsTotalAmount:"Sales",
    performanceOverview:"Perfomance Overview",
    
    data: {
    Weekly:"Last Week",
    Monthly:"Last Month",
    ThreeMonths:"Last 3 months",
    SixMonths:"Last 6 months",
    Yearly:"Last year",
     AllTimes:"All Times"
    },
    segments: {
     Leads:"Leads",
     Customers: "Customers",
     TotalAmount: "Total Amount",
     AllSegments:"All Segments"
    }
    },

    analyticsSales: {   
      data: {
      week:"Last Week",
      month:"Last Month",
      "3months":"Last 3 months",
      "6months":"Last 6 months",
      year:"Last year",
      all:"All Times"
      },
      },


  leads: {
    leadsTitle: "Leads",
    leadsTableName: "Leads List",
    leadFilter: {
      filter: "Filter",
      whenToCall: "When to Call",
      nearestToCurrentDate: "Nearest to Current Date",
      farthestFromCurrentDate: "Farthest from Current Date",
      status: "Status",
    },
    leadsAdd: "Add Lead",
    leadsTable: {
      id: "ID",
      name: "Name",
      phone: "Phone",
      product: "Product",
      whenToCall: "When to Call",
      status: "Status",
      actions: "Actions",
    },
    leadmodal: {
      leadsModalTitleAdd: "Add New Lead",
      leadsModalTitleEdit: "Edit Lead",
      name: "Name",
      phone: "Phone Number",
      whenToCall: "When to Call",
      status: "Status",
      interested: "Interested",
      contracted: "Contracted",
      potential: "Potential",
      purchased: "Purchased",
      offered: "Offered",
      deleteButton: "Delete",
      saveButton: "Save",
      closeButton: "Close",
      commentTitle: "Comments",
      commentPlaceholder: "Add a comment",
      commentSave: "Save",
      commentSend: "Send",
      confirmText: "Are you sure you want to delete this lead?",
    },
  },
  customers: {
    customerTitle: "Customers",
    customersTableName: "Customer List",
    customerAddButton: "Add Customer",
    customerNoData: " No customers available",
    customerTable: {
      id: "ID",
      name: "Name",
      phoneNumber: "Phone Number",
      totalSpend: "Total Spend",
      contracts: "Contracts",
      actions: "Actions",
      date: "Date",
    },
    customerModal: {
      editCustomer: "Edit Customer",
      addCustomer: "Add Customer",
      name: "Name",
      phone: "Phone Number",
      close: "Close",
      save: "Save",
      delete: "Delete",
      deleteConfirmation: "Are you sure you want to delete this customer?",
    },
    customerContactModal: {
      customerContractModal: "Contracts of ",
      customerContractModalId: "ID",
      customerContractModalName: "Name",
      customerContractModalPrice: "Price",
      customerContractModalQuantity: "Quanity",
      customerContractModalDate: "Date",
      customerContractModalTotalAmount: "Total amount",
      customerContractModalManagerName: "Manager",
    },
  },
  contracts: {
    contractTitle: "Contracts",
    contractTableName: "Contracts List",
    contractAddBTN: "Add Contract",
    contractsNoData: "No contracts available",
    fiter: {
      contractFilterTitle: "Filter",
      contractFilterBy: "Date",
      contractFilterByName: "Name",
      productNameFilter: "Product",
      contractFilterByA: "Ascending",
      contractFilterByD: "Descending",
    },
    table: {
      contractTableId: "ID",
      contractTableProduct: "Product",
      contractTableCustomer: "Customer",
      contractTableUnits: "Units",
      contractTableDate: "Date",
      contractTablePrice: "Price",
      contractTableManager: "Manager",
      contractTableTotalsum: "Total Sum",
      contractTableActions: "Actions",
      contractTableQuantity: "Quantity",
    },
    contractsModal: {
      contractEdit: "Edit Contract",
      contractAdd: "Add Contract",
      contractProduct: "Product",
      contractProductPlaceholder: "Search products...",
      contractNoProduct: "No products found",
      contractCustomer: "Customer",
      contractCustomerPlaceholder: "Search customer...",
      contractNoCustomer: "No customers found",
      contractPrice: "Price",
      contractUnits: "Units",
      contractManager: "Manager",
      contractManagerPlaceholder: "Search manager...",
      contractNoManager: "No managers found",
      contractDate: "Date",
      contractDeleteBTN: "delete",
      contractSaveBTN: "Save",
      contractAddBTN: "Add",
      contractCancelBTN: "Cancel",
    },
  },
  products: {
    productsTitle: "Stock product",
    productTableTitle: "Orders",
    productsNoProduct: "No products found",
    productTable: {
      productTableId: "ID",
      productTableName: "Name",
      productTablePurchased: "Purchased",
      productTableSold: "Sold",
      productTableStock: "Stock",
    },
  },
  managers: {
    managerTitle: "Managers",
    managerTableTitle: "Managers List",
    managerAddBTN: "Add Manager",
    managerNoManager: " No managers available",
    managerModal: {
      managerModalEdit: "Edit Manager",
      managerModalAdd: "Add Manager",
      managerModalName: "Name",
      managerModalPhone: "Phone",
      managerModalDeleteBTN: "Delete",
      managerModalCancelBTN: "Cancel",
      managerModalSaveBTN: "Save Changes",
      managerModalAddBTN: "Add Manager",
      managerModalConfirmTitle: "Confirm Deletion",
      managerModalConfirmText:
        " Are you sure you want to delete this manager? This action cannot be undone.",
      managerModalConfirmCencel: "Cancel",
      managerModalConfirmConfirm: "Confirm",
    },
    managersTable: {
      managerTableId: "ID",
      managerTableName: "Name",
      managerTablePhone: "Phone Number",
      managerTableSales: "Sales",
      managerTableContracts: "Contracts",
      managerTableActions: "Actions",
    },
    managersContractsModal: {
      managerContractsTitle: "Customer Details",
      managerContractsFilterTitle: "Product",
      managerContractsFilterSelect: "Select a product",
      managerContractTableCustomer: "Customer",
      managerContractTableDate: "Date",
      managerContractTablePrice: "Price",
      managerContractTableProduct: "Product",
      managerContractTableQuantity: "Quantity",
      managerContractTableTotal: "Total",
      managerContractTableNoData: "No contracts available.",
      managerContractTableClose: "Close",
      managerContractTableSave: "Save",
      managerTotalSales: "Total sales amount: ",
    },
  },
  purchases: {
    purchasesTitle: "Purchases",
    purchasesTableTitle: "Purchase List",
    purchasesAddBTN: "Add Purchase",
    purchasesNoData: "No purchases available",
    purchasesFilterTitle: "All Items",
    purchasesTable: {
      purchasesTableId: "ID",
      purchasesTableSupplier: "Supplier",
      purchasesTableItems: "Items",
      purchasesTableQuantity: "Quantity",
      purchasesTableFree: "Free",
      purchasesTableAdditionalCost: "Additional Cost",
      purchasesTablePrice: "Average Product Cost",
      purchasesTableTotal: "Total",
      purchasesTableDate: "Date",
      purchasesTableActions: "Actions",
    },
    purchasesModal: {
      purchasesModalSupplier: "Supplier",
      purchasesModalName: "Name",
      purchasesModalQuantity: "Quantity",
      purchasesModalQuantityForFree: "Quantity For Free",
      purchasesModalPrice: "Avarage product cost",
      purchasesModalAdditionalCost: "Additional Cost",
      purchasesModalDate: "Date",
      purchasesModalDeleteBTN: "Delete",
      purchasesModalCloseBTN: "Close",
      purchasesModalSaveBTN: "Save",
      purchasesModalConfirmTitle: "Confirm Deletion",
      purchasesModalConfirmText:
        "Are you sure you want to delete this item? This action cannot be undone.",
      purchasesModalConfirmCancelBTN: "Cancel",
      purchasesModalConfirmConfirmBTN: "Confirm",
      purchasesShippingCosts: "Shipping Costs",
      purchasesCustomDuties: "Custom Duties",
      purchasesVat: "VAT",
      purchasesPaymentProcessingFees: "Payment Processing Fees",
      purchasesCurrencyConversionFees: "Currency Conversion Fees",
      purchasesStorageCosts: "Storage Costs",
      purchasesCertificationFees: "Certification Fees",
      purchasesLocalDeliveryCosts: "Local Delivery Costs",
      purchasesInsuranceCosts: "Insurance Costs",
      purchasesBrokerageFees: "Brokerage Fees",
      purchasesRepackagingFees: "Repackaging Fees",
      purchasesEnvironmentalTaxes: "Environmental Taxes",
      purchasesOthers: "Others",
    },
  },
  conversions: {
    conversionsTitle: "Conversions",
    conversionsTableTitle: "Leads conversions",
  },
  notification: {
    notificationViewAll: "Mark all as read",
    notificationNoDate: "You have not notifications",
    notificationText: "Don't forget to call: ",
  },
  sales: {
    salesTitle: "Sales",
    salesProducts: "Products sold",
    salesProductsChart: "Quantity sold",
    salesContracts: "Total sold",
    salesProfit: "Total profit",
    filterAll: "All",
  },
};

export default translationsEN;
