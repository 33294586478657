import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logo } from "../assets";
import { useTranslation } from "react-i18next";
import navigationLinks from "../constants/links";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

export default function Sidebar({ isDarkMode }) {
  const role = localStorage.getItem("role");
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const navigateToHome = () => {
    navigate("/");  // Navigate to the home route
  };

  const isMobile = window.innerWidth < 1024; // Adjust breakpoint as needed

  // Filter navigation links based on role
  const filteredLinks = role === "director" ? navigationLinks : navigationLinks.filter((link) => link.role === "manager");

  useEffect(() => {
    // Check if current location is a restricted route and redirect if necessary
    const restrictedRoutes = filteredLinks.map((link) => link.route);
    if (
      !restrictedRoutes.includes(location.pathname) &&
      location.pathname !== "/leads"
    ) {
      navigate("/leads");  // Default to /leads if no valid route is found
    }
  }, [location, filteredLinks, navigate]);

  return isMobile ? (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 50,
        overflowX: "auto",
        display: "flex",
        backgroundColor: isDarkMode ? "gray.800" : "#FAFAFB",
      }}
      className="scrollbar-hide"
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(filteredLinks[newValue]?.route || "/");
        }}
        sx={{
          width: "max-content", 
          backgroundColor: isDarkMode ? "gray.800" : "#FAFAFB",
          color: isDarkMode ? "gray.300" : "gray.800",
        }}
      >
        {filteredLinks.map(({ display_name, icon }, index) => (
          <BottomNavigationAction
            key={index}
            label={t(`sideBar.${display_name}`)}
            icon={<i className={`${icon} text-[24px]`} />}
            sx={{
              "&.Mui-selected": {
                color: "#37B5FE",
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Box>
  ) : (
    <div
      className={`overflow-y-auto scrollbar-hide z-50 border-r w-full fixed bottom-0 left-0 right-0 lg:top-0 lg:left-0 lg:h-screen lg:w-80 bg-[#FAFAFB] dark:bg-gray-800 ${
        isDarkMode ? "text-gray-300" : "text-gray-800"
      }`}
    >
      <div className="lg:flex flex-col hidden items-center justify-center h-32 border-gray-200 dark:border-gray-700 my-4 cursor-pointer">
        <img src={logo} alt="Logo" className="h-24" onClick={navigateToHome} />
      </div>

      <nav className="lg:mb-10 flex items-center justify-between gap-5 lg:block">
        {filteredLinks.map(({ route, display_name, icon }) => (
          <Link
            key={route}
            to={route}
            className={`flex items-center p-4 mx-3 rounded-md transition-all duration-200 ${
              location.pathname === route
                ? "bg-[#37B5FE] text-white"
                : isDarkMode
                ? "hover:bg-gray-700 text-gray-300"
                : "hover:bg-gray-200 text-gray-800"
            }`}
          >
            <i
              className={`${icon} text-[24px] mx-4 ${
                location.pathname === route ? "text-white" : "text-[#37B5FE]"
              }`}
            ></i>
            <span className="font-medium text-[16px]">
              {t(`sideBar.${display_name}`)}
            </span>
          </Link>
        ))}
      </nav>
    </div>
  );
}
