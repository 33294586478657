import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";

export default function Search({ isDarkMode, onSearch }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const handleSearch = (event) => {
    onSearch(event.target.value);
  };

  return (
    <>
      <div
        className={`relative rounded-md overflow-hidden hidden lg:block transition-all duration-700 ease-in-out`}
        style={{
          width: isExpanded ? "200%" : "30%", // Adjust width on expansion
          minWidth: 200,
        }}
      >
        <input
          type="text"
          onFocus={() => setIsExpanded(true)}
          onBlur={() => setIsExpanded(false)}
          onChange={handleSearch}
          className="w-full pl-10 pr-4 py-2 rounded-lg focus:outline-none dark:bg-slate-500 dark:text-gray-50 bg-gray-100 text-gray-800 dark:placeholder-gray-300"
          placeholder={t("search.searchPlaceholder")}
        />
        <div className="absolute left-3 top-2.5 dark:text-gray-50 text-gray-500">
          <CiSearch className="h-5 w-5 cursor-pointer hover:text-[#37B5FE]" />
        </div>
      </div>
      <div
        className={`relative rounded-md overflow-hidden lg:hidden transition-all duration-700 ease-in-out`}
        style={{
          width: "30%",
          minWidth: 200,
        }}
      >
        <input
          type="text"
          onFocus={() => setIsExpanded(true)}
          onBlur={() => setIsExpanded(false)}
          onChange={handleSearch}
          className="w-full pl-10 pr-4 py-2 rounded-lg focus:outline-none dark:bg-slate-500 dark:text-gray-50 bg-gray-100 text-gray-800 dark:placeholder-gray-300"
          placeholder={t("search.searchPlaceholder")}
        />
        <div className="absolute left-3 top-2.5 dark:text-gray-50 text-gray-500">
          <CiSearch className="h-5 w-5 cursor-pointer hover:text-[#37B5FE]" />
        </div>
      </div>
    </>
  );
}
