import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";

const ProductsChart = ({ totatSold }) => {
  const { t } = useTranslation();

  const [chartData, setChartData] = useState([]);
  const [animatedNumber, setAnimatedNumber] = useState(0);
  const [filter, setFilter] = useState("week");

  const fetchMarginData = async (period) => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");

      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/analytics/margin/${period}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch margin data");
      }

      const data = await response.json();
      return data && data.productAnalytics ? data : { productAnalytics: {} }; // Default qiymat
    } catch (error) {
      console.error("Error fetching margin data:", error);
      return { productAnalytics: {} }; // Xatolik bo'lsa bo'sh obyekt
    }
  };

  const formatChartData = (products) => {
    if (!products || Object.keys(products).length === 0) {
      setChartData([]); // Ma'lumot bo'lmasa bo'sh massiv
      return;
    }

    let totalSold = 0;
    const formattedData = Object.values(products)
      .filter((product) => product.quantitySold > 0)
      .map((product) => {
        totalSold += product.quantitySold;
        return {
          label: product.name,
          value: product.quantitySold,
          name: product.name,
          purchases: product.sales.usd,
          quantitySold: product.quantitySold,
          totalPrice: product.sales.usd,
        };
      });

    setChartData(formattedData);
  };

  useEffect(() => {
    const getFilteredData = async () => {
      if (filter) {
        const data = await fetchMarginData(filter);
        if (data) {
          formatChartData(data.productAnalytics);
        }
      }
    };

    getFilteredData();
  }, [filter]);

  useEffect(() => {
    let start = 0;
    const end = totatSold || 0;
    const duration = 2000;
    const stepTime = 50;

    const increment = Math.ceil(end / (duration / stepTime));

    const interval = setInterval(() => {
      start += increment;
      if (start >= end) {
        start = end;
        clearInterval(interval);
      }
      setAnimatedNumber(start);
    }, stepTime);

    return () => clearInterval(interval);
  }, [totatSold]);

  const colors = [
    "#4a90e2", "#5e8bc4", "#3b5998", "#4a74d0", "#66b3ff",
    "#8ab1e4", "#0066cc", "#3385cc", "#00bfff", "#1e90ff",
  ];

  const chartDataWithColors = chartData.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));

  const renderTooltip = ({ name, purchases, quantitySold, totalPrice }) => (
    <div className="dark:text-white">
      <strong>Name:</strong> {name}
      <br />
      <strong>Purchases:</strong> {purchases}
      <br />
      <strong>Quantity Sold:</strong> {quantitySold}
      <br />
      <strong>Total Price (USD):</strong> ${totalPrice.toFixed(2)}
    </div>
  );

  return (
    <div className="border rounded-md p-5 dark:border-gray-700 dark:bg-gray-800">
      <div className="flex items-center justify-between gap-5">
        <h3 className="text-lg font-medium my-5 dark:text-white">
          {t("sales.salesProducts")}
        </h3>
      </div>
      {chartData.length > 0 ? (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <div className="relative">
            <PieChart
              series={[{
                paddingAngle: 1,
                innerRadius: 50,
                outerRadius: 120,
                data: chartDataWithColors,
              }]}
              margin={{ right: 10 }}
              width={250}
              height={250}
              legend={{ hidden: true }}
              tooltipContent={(data) => renderTooltip(data)}
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg font-semibold dark:text-white">
              <span>{animatedNumber}</span>
            </div>
          </div>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <div className="relative">
            <PieChart
              series={[{
                paddingAngle: 1,
                innerRadius: 50,
                outerRadius: 125,
                data: [{ label: "", value: 1, color: "#f0f0f0" }], // Bo'sh grafik
              }]}
              margin={{ right: 10 }}
              width={250}
              height={250}
              legend={{ hidden: true }}
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold dark:text-white">
              0 
            </div>
          </div>
        </Stack>
      )}
    </div>
  );
};

export default ProductsChart;
