import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

const ManagerTable = ({ managers, onSalesEditClick, onEditManager }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-y-auto h-[40vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("managers.managersTable.managerTableId")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("managers.managersTable.managerTableName")}
            </th>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("managers.managersTable.managerTablePhone")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("managers.managersTable.managerTableSales")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("managers.managersTable.managerTableContracts")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("managers.managersTable.managerTableActions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {managers.map((manager, index) => (
            <tr key={manager.id} className="border-t">
              <td className="px-4 py-2 text-gray-800 dark:text-white hidden md:table-cell">
                {index + 1}
              </td>
              <td className="p-2 md:py-2 text-[10px] md:text-sm capitalize text-gray-800 dark:text-white">
                {manager.name}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white hidden md:table-cell">
                {manager.phone}
              </td>
              <td className="px-1 md:px-4 py-2 text-[10px] md:text-sm text-gray-800 dark:text-white">
                {manager.totalItemsSold}
              </td>
              <td className="p-2 md:px-4 py-2 text-start">
                <button
                  onClick={() => onSalesEditClick(manager)}
                  className="text-green-600 hover:text-green-800 dark:text-green-400"
                >
                  <ClipboardDocumentCheckIcon className="w-5 h-5 md:w-6 md:h-6 text-[#37B5FE]" />
                </button>
              </td>
              <td className="p-2 md:px-4 py-2 text-start">
                <button
                  onClick={() => onEditManager(manager)}
                  className="text-blue-600 hover:text-blue-800 dark:text-blue-400"
                >
                  <PencilSquareIcon className="w-5 h-5 md:w-6 md:h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagerTable;
