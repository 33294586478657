import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export default function PurchaseTable({ purchases, onEditPurchase }) {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState("usd");

  useEffect(() => {
    const storedCurrency = localStorage.getItem("currency") || "usd";
    setCurrency(storedCurrency);
  }, []);

  const formatCurrency = (amount) => {
    if (!amount) return currency === "usd" ? "$0" : "0 UZS";
    return currency === "usd"
      ? `$${amount.toLocaleString()}`
      : `${amount.toLocaleString()} UZS`;
  };
  

  return (
    <div className="overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800">
          <tr>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("purchases.purchasesTable.purchasesTableId")}
            </th>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("purchases.purchasesTable.purchasesTableSupplier")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("purchases.purchasesTable.purchasesTableItems")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("purchases.purchasesTable.purchasesTableQuantity")}
            </th>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("purchases.purchasesTable.purchasesTableFree")}
            </th>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("purchases.purchasesTable.purchasesTableAdditionalCost")}
            </th>
            <th className="px-4 py-2 w-[120px] text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("purchases.purchasesTable.purchasesTablePrice")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("purchases.purchasesTable.purchasesTableTotal")}
            </th>
            <th className="px-4 py-2 text-sm font-semibold text-gray-600 dark:text-white text-left hidden md:table-cell">
              {t("purchases.purchasesTable.purchasesTableDate")}
            </th>
            <th className="px-1 md:px-4 py-2 text-xs md:text-sm font-semibold text-gray-600 dark:text-white text-left">
              {t("purchases.purchasesTable.purchasesTableActions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase, index) => (
            <tr key={purchase.id} className="border-t">
              <td className="px-4 py-2 text-gray-800 dark:text-white hidden md:table-cell">
                {index + 1}
              </td>
              <td className="p-2 truncate max-w-[20px] text-xs md:text-base hidden md:table-cell md:px-4 md:py-2 text-gray-800 dark:text-white">
                {purchase.supplierInfo}
              </td>
              <td className="p-2 md:px-4 max-w-[100px] text-xs md:text-base truncate md:py-2 text-gray-800 dark:text-white">
                {purchase.productName}
              </td>
              <td className="p-2 md:px-4 max-w-[20px] text-xs md:text-base truncate text-gray-800 dark:text-white">
                {purchase.quantity?.toLocaleString()}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white hidden md:table-cell">
                {purchase.quantityForFree}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white hidden md:table-cell">
              {formatCurrency(purchase.additionalCost?.[currency] || 0)}
              </td>
              <td className="px-4 py-2 w-[120px] text-gray-800 dark:text-white hidden md:table-cell">
              {formatCurrency(purchase?.avarageProductCost?.[currency] || 0)}
              </td>
              <td className="p-2 md:px-4 max-w-[120px] text-xs md:text-base truncate text-gray-800 dark:text-white">
              {formatCurrency(purchase.totalCost?.[currency] || 0)}
              </td>
              <td className="px-4 py-2 text-gray-800 dark:text-white hidden md:table-cell">
                {purchase.purchaseDate}
              </td>
              <td className="p-2 md:px-4 max-w-[20px] text-xs md:text-base truncate">
                <button onClick={() => onEditPurchase(purchase)}>
                  <PencilSquareIcon className="w-6 h-6 text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
