import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { PurchaseModal, PurchaseTable, Search } from "../components";
import PurchaseFilter from "../components/Purchase-components/purchase-filter";
import dayjs from "dayjs";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";

const BASE_URL = "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api";

export default function Purchases({ isDarkMode }) {
  const [purchases, setPurchases] = useState([]);
  const [filteredPurchases, setFilteredPurchases] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [productNames, setProductNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [callDates, setCallDates] = useState([]);

  const { t } = useTranslation();
  const token = localStorage.getItem("accessToken");
  const csrfToken = localStorage.getItem("csrfToken");

  const fetchPurchases = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BASE_URL}/purchases`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "CSRF-Token": csrfToken,
        },
      });
      const result = await response.json();

      if (result.success && Array.isArray(result.data)) {
        const formattedPurchases = result.data.map((purchase, index) => ({
          ...purchase,
          _id: purchase._id,
          id: index + 1,
          totalCost: purchase.totalCost || purchase.price * purchase.quantity,
          purchaseDate: purchase.purchaseDate
            ? dayjs(purchase.purchaseDate).format("YYYY-MM-DD")
            : "N/A",
        }));
        setPurchases(formattedPurchases);
        setProductNames([
          ...new Set(formattedPurchases.map((p) => p.productName)),
        ]);
        setCallDates([
          ...new Set(
            formattedPurchases.map((purchase) =>
              dayjs(purchase.purchaseDate).format("YYYY-MM-DD")
            )
          ),
        ]);
      } else {
        toast.error("Failed to load purchases.");
      }
    } catch (error) {
      toast.error("Error fetching purchases.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchases();
  }, []);

  useEffect(() => {
    const filtered = purchases.filter((purchase) => {
      const productName = purchase.productName || "";
      const matchesSearch = productName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesFilter = selectedItem === "" || productName === selectedItem;
      return matchesSearch && matchesFilter;
    });
    setFilteredPurchases(filtered);
  }, [purchases, searchQuery, selectedItem]);

  const handleSave = (updatedPurchase) => {
    fetchPurchases();
    setPurchases((prev) => {
      const index = prev.findIndex((p) => p._id === updatedPurchase._id);
      const formattedPurchase = {
        ...updatedPurchase,
        id: index >= 0 ? index + 1 : prev.length + 1,
        purchaseDate: updatedPurchase.purchaseDate
          ? dayjs(updatedPurchase.purchaseDate).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
      };

      if (index >= 0) {
        const newPurchases = [...prev];
        newPurchases[index] = formattedPurchase;
        toast.success("Purchase updated successfully!");
        return newPurchases;
      }

      toast.success("Purchase added successfully!");
      return [...prev, formattedPurchase];
    });
    setIsModalOpen(false);
  };

  const handleFilterByDate = (isAscending) => {
    const sorted = [...purchases].sort((a, b) => {
      const dateA = dayjs(a.purchaseDate).valueOf();
      const dateB = dayjs(b.purchaseDate).valueOf();
      return isAscending ? dateA - dateB : dateB - dateA;
    });
    setFilteredPurchases(sorted);
  };

  const handleFilterByItem = (item) => {
    const filtered = purchases.filter(
      (purchase) => purchase.productName === item
    );
    setFilteredPurchases(filtered);
  };

  return (
    <div className="min-h-screen mb-8 lg:mb-0 lg:ml-10 min-w-full px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl bg-[#FAFAFB] dark:bg-[#1F2937]">
      <div className="max-w-full min-h-screen px-4 mx-auto bg-[#FAFAFB] dark:bg-[#1F2937]">
        <h1 className="text-2xl font-bold mb-6 text-black dark:text-white">
          {t("purchases.purchasesTitle")}
        </h1>
        <div className="bg-white dark:bg-gray-700 shadow rounded-lg p-4">
          <div className="lg:flex flex-col sm:flex-row justify-between items-center gap-4 mb-4">
            <h2 className="text-xl font-semibold text-black dark:text-white">
              {t("purchases.purchasesTitle")}
            </h2>
            <div className="hidden lg:block">
              <Search isDarkMode={isDarkMode} onSearch={setSearchQuery} />
            </div>
            <div className="max-w-[300px] lg:hidden">
              <Search isDarkMode={isDarkMode} onSearch={setSearchQuery} />
            </div>
            <div className="flex gap-2 mt-3 lg:mt-0">
              <PurchaseFilter
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                uniqueItems={productNames}
                callDates={callDates}
                handleFilterByItem={handleFilterByItem}
                handleFilterByDate={handleFilterByDate}
              />
              <button
                onClick={() => {
                  setIsModalOpen(true);
                  setSelectedPurchase(null);
                }}
                className="bg-blue-600 hover:bg-blue-700 text-white text-sm p-2 md:px-4 md:py-2 rounded-md flex items-center gap-1 ml-4"
              >
                <PlusIcon className="w-5 h-5" />
                {t("purchases.purchasesAddBTN")}
              </button>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : filteredPurchases && filteredPurchases.length > 0 ? (
            <PurchaseTable
              purchases={filteredPurchases}
              onEditPurchase={(purchase) => {
                setSelectedPurchase(purchase);
                setIsModalOpen(true);
              }}
            />
          ) : (
            <p className="text-gray-700 dark:text-gray-300">
              {t("purchases.purchasesNoData")}
            </p>
          )}
        </div>
        {isModalOpen && (
          <PurchaseModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            isEditMode={Boolean(selectedPurchase)}
            selectedPurchase={selectedPurchase}
            onSave={handleSave}
            productNames={productNames}
            fetchPurchases={fetchPurchases}
          />
        )}
        <ToastContainer />
      </div>
    </div>
  );
}