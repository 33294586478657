import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";

const ProductsChart = ({ period }) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState([]);
  const [products, setProducts] = useState([]);
  const [animatedData, setAnimatedData] = useState([]);

  const fetchProducts = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");

      const response = await fetch(
        "https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/products",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }

      const data = await response.json();
      const filteredProducts = data.filter((product) => product.quantitySold >= 1);
      setProducts(filteredProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchMarginData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const csrfToken = localStorage.getItem("csrfToken");

      const productIds = products.map((product) => product._id).join(",");
      const response = await fetch(
        `https://amalify-server-82d7e0343c9c.herokuapp.com/amalify/api/analytics/margin/${period}?productIds=${productIds}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "CSRF-Token": csrfToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch margin data");
      }

      const data = await response.json();
      return data?.analytics?.productAnalytics || {};
    } catch (error) {
      console.error("Error fetching margin data:", error);
      return {};
    }
  };

  const formatDataForChart = (productAnalytics) => {
    const chartData = Object.keys(productAnalytics)
      .map((id) => {
        const analytics = productAnalytics[id];
        return {
          label: analytics?.name || "Unknown",
          value: analytics?.quantitySold || 0,
          color: "#00aaff",
        };
      })
      .filter((item) => item.value > 0);

    return chartData.length > 0
      ? chartData
      : [{ label: "No Data", value: 1, color: "#f0f0f0" }];
  };

  const animateData = (data) => {
    const animatedValues = data.map((item) => ({
      ...item,
      value: 0,
    }));

    setAnimatedData(animatedValues);

    data.forEach((item, index) => {
      let count = 0;
      const interval = setInterval(() => {
        count += 1;
        setAnimatedData((prevData) => {
          const updatedData = [...prevData];
          updatedData[index] = {
            ...updatedData[index],
            value: Math.min(count, item.value),
          };
          return updatedData;
        });

        if (count >= item.value) {
          clearInterval(interval);
        }
      }, 10); // Adjust this interval for animation speed
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      fetchMarginData().then((productAnalytics) => {
        const formattedData = formatDataForChart(productAnalytics);
        setChartData(formattedData);
        animateData(formattedData);
      });
    }
  }, [products, period]);

  return (
    <div className="border rounded-md p-5">
      <div className="flex items-center justify-between gap-5">
        <h3 className="text-lg font-medium my-5 dark:text-white">
          Product Sold
        </h3>
      </div>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <div className="relative">
          <PieChart
            series={[
              {
                paddingAngle: 1,
                innerRadius: 50,
                outerRadius: 125,
                data:
                  animatedData.length > 0
                    ? animatedData.map((item) => ({
                        label: item.label,
                        value: item.value,
                        color: item.color || "#00aaff",
                      }))
                    : [{ label: "No Data", value: 1, color: "#f0f0f0" }],
              },
            ]}
            margin={{ right: 10 }}
            width={250}
            height={250}
            legend={{ hidden: true }}
          />
          <div className="absolute dark:text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xs font-semibold flex items-center gap-1">
            {parseInt(animatedData[0]?.value) || "0"}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default ProductsChart;
