import React, { useState, useEffect } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function ContractTable({ contracts, onEditContract }) {
  const [currency, setCurrency] = useState("usd");
  const { t } = useTranslation();

  useEffect(() => {
    const storedCurrency = localStorage.getItem("currency") || "usd";
    setCurrency(storedCurrency);
  }, []);

  const formatCurrency = (amount) => {
    return currency === "usd"
      ? `$${amount?.toLocaleString()}`
      : `${amount?.toLocaleString()} UZS`;
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return t("contracts.noDate");
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return (
    <div className="overflow-x-auto max-h-[60vh] scrollbar-hide border rounded-md">
      <table className="w-full table-auto border-collapse">
        <thead className="sticky top-0 bg-gray-50 dark:bg-gray-800 z-10">
          <tr>
            <th className="hidden w-[10px] lg:table-cell xs:pl-4 xs:py-1 xs:text-[10px] lg:px-2 lg:py-2 text-gray-800 dark:text-white lg:text-xs text-left">
              {t("contracts.table.contractTableId")}
            </th>
            <th className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTableProduct")}
            </th>
            <th className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTableCustomer")}
            </th>
            <th className="xs:hidden lg:table-cell xs:pl-4 xs:py-1 xs:text-[10px] lg:px-2 w-[20px] lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTableQuantity")}
            </th>
            <th className="xs:hidden lg:table-cell xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 text-gray-600 dark:text-white lg:text-xs text-left">
              {t("contracts.table.contractTableDate")}
            </th>
            <th className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTablePrice")}
            </th>
            <th className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTableManager")}
            </th>
            <th className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-6 lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTableTotalsum")}
            </th>
            <th className="xs:pl-4 xs:py-1 xs:text-[10px] lg:px-2 lg:py-2 lg:text-xs font-semibold text-gray-600 dark:text-white text-left">
              {t("contracts.table.contractTableActions")}
            </th>
          </tr>
        </thead>
        <tbody>
          {contracts?.map((contract, index) => (
            <tr key={contract._id} className="border-t">
              <td className="hidden w-[10px] lg:table-cell xs:pl-1 xs:py-1 xs:text-[11px] lg:px-2 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
                {index + 1}
              </td>
              <td className="xs:pl-4 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
                {contract.productName ||
                  t("contracts.contractsModal.contractNoProduct")}
              </td>
              <td className="xs:pl-1 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
                {contract.customerName ||
                  t("contracts.contractsModal.contractNoCustomer")}
              </td>
              <td className="xs:hidden lg:table-cell xs:pl-1 lg:px-2 w-[20px] xs:py-1 xs:text-[11px] lg:pl-8 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
                {contract.quantity !== undefined
                  ? contract.quantity
                  : t("contracts.noQuantity")}
              </td>
              <td className="xs:hidden lg:table-cell xs:pl-1 xs:py-1 xs:text-[11px] lg:px-4 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
                {contract.contractDate
                  ? formatDateTime(contract.contractDate)
                  : t("contracts.noDate")}
              </td>
              <td className="xs:hidden lg:table-cell xs:pl-1 xs:py-1 xs:text-[11px] lg:px-4 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
  {currency === "usd"
    ? formatCurrency(contract?.price?.usd)
    : `${contract?.price?.uzs?.toLocaleString()} UZS`}
</td>

              <td className="xs:pl-4 xs:py-1 xs:text-[11px] lg:px-6 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
                {contract?.managerName ||
                  t("contracts.contractsModal.contractNoManager")}
              </td>
              <td className="xs:hidden lg:table-cell xs:pl-1 xs:py-1 xs:text-[11px] lg:px-4 lg:py-2 text-gray-800 dark:text-white lg:text-xs">
  {currency === "usd"
    ? formatCurrency(contract?.totalAmount?.usd)
    : `${contract?.totalAmount?.uzs?.toLocaleString()} UZS`}
</td>

              <td className="xs:pl-6 lg:pl-0 xs:py-1 xs:text-[11px] lg:px-2 lg:py-2 text-gray-800 dark:text-white lg:text-xs flex justify-center items-center">
                <button onClick={() => onEditContract(contract)}>
                  <PencilSquareIcon className="w-4 h-auto text-[#37B5FE]" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
